import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import productSampleImage from "./img/gc_icon.png";
import CloseIcon from "@mui/icons-material/Close";
import { FlashAuto } from "@mui/icons-material";
import Cookies from "js-cookie";
import fallbackImage from "./img/7fd2cdd1-31a7-4810-bf03-137aaea45f12.jpeg";

const Cart = ({ onCreateQuote }) => {
  const [allCart, setAllCart] = useState([]);

  const token = Cookies.get("token");

  const [allproduct, setAllproduct] = useState([]);
  const getProducts = async () => {
    try {
      const response = await fetch(
        "https://www.sales.gclightinggallery.com/laravel/api/getproducts",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const gottenProducts = await getProducts();
      if (gottenProducts) {
        const renamedProducts = gottenProducts.product.map((product) => {
          return {
            productImage: product.img_src,
            productName: product.name,
            productDescription: product.description,
            SKU: product.sku,
            UnitPrice: product.unit_price,
            Weight: product.weight,
            Length: product.length,
            Width: product.width,
            Height: product.height,
            Category: product.categories,
            Tag: product.tags,
            Brand: product.brand,
            AttributeColor: product.luminance,
            AttributeSize: product.size,
            Stock: product.stock,
            // ... other fields you want to rename and include
          };
        });

        console.log(renamedProducts);
        setAllproduct(renamedProducts);
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchProducts();
  }, []);
  const [creatingCart, setCreatingCart] = useState(false);
  const [enteredName, setEnteredName] = useState(false);
  const [activeid, setActiveid] = useState("");
  const [activeClientName, setActiveClientName] = useState("");
  const [activeClientCart, setActiveClientCart] = useState([]);
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  const [selectedFilters, setSelectedFilters] = useState([]);

  // Function to handle changes in selected filters
  const handleFilterChange = (filter) => {
    if (selectedFilters.includes(filter)) {
      // Filter is already selected, remove it
      setSelectedFilters(selectedFilters.filter((f) => f !== filter));
    } else {
      // Filter is not selected, add it
      setSelectedFilters([...selectedFilters, filter]);
    }
  };

  // Function to filter the products based on selected filters
  const filteredproducts = allproduct.filter((product) => {
    const tags = product.Tag || ""; // Default to an empty string if tags is null
    const categories = product.Category || ""; // Default to an empty string if categories is null

    const tagsAndCategories = [
      ...tags.split(" | "),
      ...categories.split(" | "),
    ];
    console.log(selectedFilters);
    return selectedFilters.length === 0
      ? allproduct
      : selectedFilters.some((filter) => tagsAndCategories.includes(filter));
  });

  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Function to filter invoices based on search query
  const searchedproducts = filteredproducts.filter((product) => {
    const { productDescription, productName, SKU } = product;
    const lowerCaseSearchQuery = searchQuery.toLowerCase();

    return (
      (productDescription &&
        productDescription.toLowerCase().includes(lowerCaseSearchQuery)) ||
      (productName &&
        productName.toLowerCase().includes(lowerCaseSearchQuery)) ||
      (SKU && SKU.toLowerCase().includes(lowerCaseSearchQuery))
    );
  });

  const handleproductClick = async (product) => {
    const updatedCart = [...activeClientCart];
    const existingproductIndex = updatedCart.findIndex(
      (cartItem) => cartItem.SKU === product.SKU
    );

    if (existingproductIndex !== -1) {
      // product already exists in the cart, update its quantity
      updatedCart[existingproductIndex].quantity += 1;
    } else {
      // product does not exist in the cart, add it with quantity 1
      updatedCart.push({ ...product, quantity: 1 });
    }

    setActiveClientCart(updatedCart);
    console.log(updatedCart);
  };

  const [cartOpen, setCartOpen] = useState(false);

  const getTotalQuantityInCart = () => {
    return activeClientCart.reduce((totalQuantity, cartItem) => {
      return totalQuantity + cartItem.quantity;
    }, 0);
  };

  const getTotalQuantityIndividualCart = () => {
    return activeClientCart.reduce((totalQuantity, cartItem) => {
      return totalQuantity + cartItem.quantity;
    }, 0);
  };

  const calculateTotalValue = () => {
    let totalValue = 0;

    for (const product of activeClientCart) {
      const quantity = product.quantity;
      const unitPrice = parseFloat(product.UnitPrice); // Assuming the UnitPrice is a string, convert it to a number if needed
      const productValue = quantity * unitPrice;
      totalValue += productValue;
    }

    return totalValue;
  };

  const [tax, setTax] = useState("8");

  const generateRandomId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000000); // You can adjust the random range as needed
    return `Cart${timestamp}${random}`;
  };

  const handleSaveCart = async () => {
    const newCart = {
      id: activeid ? activeid : null,
      name: activeClientName,
      updated_at: formattedDate,
      product: activeClientCart,
    };

    setEnteredName(false);
    setCartOpen(false);
    setCreatingCart(false);
    setActiveClientCart([]);
    setActiveClientName("");

    if (activeid) {
      // Update existing cart

      try {
        const response = await fetch(
          "https://www.sales.gclightinggallery.com/laravel/api/createandupdatecart",
          {
            body: JSON.stringify(newCart),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            method: "POST",
          }
        );
        if (!response.ok) {
          // setSignUpError(true);
          throw new Error("Network response was not ok");
        }

        // Clear the form data after adding the product
      } catch (error) {
        // setSignUpError(true);
        console.error("Error signing up:", error);
      }

      setAllCart((prevAllCart) =>
        prevAllCart.map((cart) =>
          cart.id === activeid ? { ...cart, ...newCart } : cart
        )
      );
    } else {
      // Create a new cart
      try {
        const response = await fetch(
          "https://www.sales.gclightinggallery.com/laravel/api/createandupdatecart",
          {
            body: JSON.stringify(newCart),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            method: "POST",
          }
        );
        if (!response.ok) {
          // setSignUpError(true);
          throw new Error("Network response was not ok");
        }

        // Clear the form data after adding the product
      } catch (error) {
        // setSignUpError(true);
        console.error("Error signing up:", error);
      }

      setAllCart((prevAllCart) => [...prevAllCart, newCart]);
    }

    setActiveid(""); // Reset activeid after saving/updating the cart
  };

  const sortedCarts = allCart.sort(
    (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
  );

  const [searchCartQuery, setSearchCartQuery] = useState("");

  // Function to handle search input change
  const handleSearchCartInputChange = (e) => {
    setSearchCartQuery(e.target.value);
  };

  // Function to filter invoices based on search query
  const searchedCart = sortedCarts.filter((product) => {
    const { name } = product;
    const lowerCaseSearchQuery = searchCartQuery.toLowerCase();

    return name.toLowerCase().includes(lowerCaseSearchQuery);
  });

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
      console.log(window.innerWidth);
      console.log(window.innerHeight);
    };
    handleResize();
    // Add event listener to update the screenWidth state when the window is resized
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getCart = async () => {
    try {
      const response = await fetch(
        "https://www.sales.gclightinggallery.com/laravel/api/getcart",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchCart = async () => {
      const gottenCart = await getCart();
      if (gottenCart) {
        console.log(gottenCart.carts);
        setAllCart(gottenCart.carts);
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchCart();
  }, []);

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const handleGenerateQuote = () => {
    onCreateQuote(activeClientCart, activeClientName);
  };

  const [clientData, setClientData] = useState([]);
  const getCustomer = async () => {
    try {
      const response = await fetch(
        "https://www.sales.gclightinggallery.com/laravel/api/getcustomer",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };
  const [matchClientIndex, setMatchClientIndex] = useState("");
  useEffect(() => {
    const fetchCustomers = async () => {
      const gottonCustomer = await getCustomer();
      if (gottonCustomer) {
        console.log(gottonCustomer.customers);
        setClientData(gottonCustomer.customers);
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchCustomers();
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {/* Note:cart pop up */}
      <div
        style={{
          position: "fixed",
          width: "450px",
          height: cartOpen ? "70vh" : "70px",
          background: "white",
          right: 20,
          bottom: 0,
          boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
          borderRadius: "15px 15px 0px 0px",
          display: enteredName ? "flex" : "none",
          flexDirection: "column",
          justifyContent: cartOpen ? "flex-start" : "center",
          transition: "all .3s ease-in-out",
          zIndex: 100,
        }}
      >
        <div
          style={{
            padding: "20px 20px",
            fontWeight: "700",
            fontSize: "18px",
            // width: "460px",
            transition: "all .3s ease-in-out",
            // borderBottom: "1px solid lightgrey",
            marginTop: cartOpen ? "10px" : "0px",
            height: "25px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setCartOpen(!cartOpen)}
        >
          {activeClientCart.length > 0
            ? `${getTotalQuantityInCart()} Items in cart`
            : "Cart is empty."}
          <div
            className="close_icon"
            style={{
              opacity: cartOpen ? "1" : "0",
              transition: "all 0.3s ease-in-out",
              border: "1px solid lightgrey",
              borderRadius: "50%",
              height: "40px",
              width: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <div
          style={{
            padding: "10px",
            height: cartOpen ? "410px" : "0px",
            display: cartOpen ? "flex" : "none",
            flexDirection: "column",
            overflowY: "auto",
            transition: "all .3s ease-in-out",
          }}
        >
          {activeClientCart.map((product, index) => (
            <div
              key={index}
              style={{
                background: "#f0eeeb",
                padding: "10px",
                marginBottom: "8px",
                display: "flex",
                justifyContent: "space-between",
                borderRadius: "8px",
              }}
            >
              <div>
                {creatingCart
                  ? product.productName.length > 20
                    ? product.productName.slice(0, 20) + "..."
                    : product.productName
                  : product.productName}
              </div>
              {creatingCart ? (
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      background: product.quantity > 1 ? "#e62e2e" : "grey",
                      color: "white",
                      width: "25px",
                      height: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // Decrease the quantity by 1, but not below 1
                      const updatedCart = [...activeClientCart];
                      const index = updatedCart.findIndex(
                        (item) => item.SKU === product.SKU
                      );
                      if (index !== -1 && updatedCart[index].quantity > 1) {
                        updatedCart[index].quantity -= 1;
                        setActiveClientCart(updatedCart);
                      }
                    }}
                  >
                    -
                  </div>
                  <div style={{ width: "40px", textAlign: "center" }}>
                    {product.quantity}
                  </div>
                  <div
                    style={{
                      background: "#0d8c22",
                      color: "white",
                      width: "25px",
                      height: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // Increment the quantity by 1
                      const updatedCart = [...activeClientCart];
                      const index = updatedCart.findIndex(
                        (item) => item.SKU === product.SKU
                      );
                      if (index !== -1) {
                        updatedCart[index].quantity += 1;
                        setActiveClientCart(updatedCart);
                      }
                    }}
                  >
                    +
                  </div>
                  <div
                    style={{
                      background: "#e62e2e",
                      color: "white",
                      padding: "0px 10px",
                      height: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "5px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // Remove the product from the cart
                      const updatedCart = activeClientCart.filter(
                        (item) => item.SKU !== product.SKU
                      );
                      setActiveClientCart(updatedCart);
                    }}
                  >
                    Remove
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
        <div
          style={{
            height: cartOpen ? "200px" : "0px",
            display: cartOpen ? "flex" : "none",
            transition: "all .3s ease-in-out",
            flexDirection: "column",
            borderTop: "1px solid lightgrey",
            padding: "15px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <div
              className="gc_button"
              style={{
                width: "88%",
                color: "#d1ae6e",
                border: "1px solid #d1ae6e",
                height: "55px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "20px",
                fontWeight: "600",
                background: "white",
                borderRadius: "12px",
                cursor: "pointer",
              }}
              onClick={() => {
                creatingCart ? handleSaveCart() : setCreatingCart(true);
              }}
            >
              {activeid ? "Update Cart" : "Save Cart"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <div
              className="gc_button"
              style={{
                width: "88%",
                background: "#d1ae6e",
                height: "65px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "20px",
                fontWeight: "600",
                color: "white",
                borderRadius: "12px",
                cursor: "pointer",
              }}
              onClick={() => {
                console.log(activeClientCart, activeClientName, activeid);
                handleGenerateQuote();
              }}
            >
              Generate Quote
            </div>
          </div>
        </div>
      </div>

      {/* Note:actual start */}
      <div
        style={{
          padding: "0px 20px",
          height: "70px",
          display: "flex",
          alignItems: "center",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            margin: "0px",
            fontSize: "35px",
            fontWeight: "700",
            marginRight: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            setActiveClientCart([]);
            setActiveClientName("");
            setActiveid("");
            setCartOpen(false);
            setEnteredName(false);
            setCreatingCart(false);
          }}
        >
          Carts
        </div>
        {creatingCart ? (
          <></>
        ) : (
          <input
            className="search_input"
            placeholder="Search all carts"
            type="text"
            value={searchCartQuery}
            onChange={handleSearchCartInputChange}
            style={{
              padding: "10px 20px",
              width: "20%",
              border: "none",
              background: "#f0eeeb",
              borderRadius: "8px",
              marginRight: "5px",
              marginTop: "5px",
            }}
          />
        )}
        {creatingCart ? (
          <></>
        ) : activeid ? (
          <div style={{ display: "flex" }}>
            <div
              style={{
                background: "#d1ae6e",
                borderRadius: "7px",
                display: "flex",
                alignItems: "center",
                padding: "6px 15px 6px 10px",
                cursor: "pointer",
                marginRight: "5px",
                marginTop: "5px",
              }}
              onClick={() => setCreatingCart(true)}
            >
              <AddIcon style={{ color: "white", fontSize: "18px" }} />
              <div
                style={{
                  color: "white",
                  fontSize: "13px",
                  marginBottom: "2.5px",
                }}
              >
                Update Cart
              </div>
            </div>
            <div
              style={{
                background: "#d1ae6e",
                borderRadius: "7px",
                display: "flex",
                alignItems: "center",
                padding: "6px 15px 6px 10px",
                cursor: "pointer",
                marginRight: "5px",
                marginTop: "5px",
              }}
              onClick={() => {
                setActiveClientCart([]);
                setActiveClientName("");
                setActiveid("");
                setCartOpen(false);
                setEnteredName(false);
                setCreatingCart(true);
              }}
            >
              <AddIcon style={{ color: "white", fontSize: "18px" }} />
              <div
                style={{
                  color: "white",
                  fontSize: "13px",
                  marginBottom: "2.5px",
                }}
              >
                Create Cart
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              background: "#d1ae6e",
              borderRadius: "7px",
              display: "flex",
              alignItems: "center",
              padding: "6px 15px 6px 10px",
              cursor: "pointer",
              marginRight: "5px",
              marginTop: "5px",
            }}
            onClick={() => {
              setCreatingCart(true);
              setActiveClientCart([]);
              setActiveClientName("");
              setActiveid("");
              setCartOpen(false);
            }}
          >
            <AddIcon style={{ color: "white", fontSize: "18px" }} />
            <div
              style={{
                color: "white",
                fontSize: "13px",
                marginBottom: "2.5px",
              }}
            >
              Create Cart
            </div>
          </div>
        )}
      </div>
      {creatingCart ? (
        enteredName ? (
          <div
            style={{
              width: "100%",
              borderTop: "1px solid lightgrey",
              height: "calc(100vh - 70px)",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "25%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  padding: "2.5%",
                  width: "95%",
                  display: "flex",
                  borderRight: "1px solid lightgrey",
                  borderBottom: "1px solid lightgrey",
                  alignItems: "center",
                  height: "80px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60px",
                    width: "60px",
                    background: "#282828",
                    color: "#d1ae6e",
                    fontSize: "25px",
                    fontWeight: "600",
                    borderRadius: "50%",
                    marginRight: "20px",
                  }}
                >
                  {activeClientName
                    .split(" ")
                    .slice(0, 2)
                    .map((word) => word.charAt(0))
                    .join("")
                    .toUpperCase()}
                </div>
                <div>
                  <div style={{ fontSize: "23px", fontWeight: "600" }}>
                    {activeClientName}
                  </div>
                  <div style={{ fontSize: "14px", fontWeight: "400" }}>
                    {formatDate(formattedDate)}
                  </div>
                </div>
              </div>
              <div
                style={{
                  padding: "2.5%",
                  width: "95%",
                  display: "flex",
                  borderRight: "1px solid lightgrey",
                  borderBottom: "1px solid lightgrey",
                  height: "calc(93vh - 80px)",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    overflowY: "scroll",
                    height: "calc(100vh - 250px)",
                    overflowX: "hidden",
                  }}
                >
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      marginBottom: "15px",
                    }}
                  >
                    Product Tags:
                  </div>
                  {allproduct
                    .reduce((filters, product) => {
                      const tags = product.Tag || ""; // Default to an empty string if tags is null
                      const categories = product.Category || ""; // Default to an empty string if categories is null

                      console.log(tags, categories);
                      const tagsAndCategories = [...tags.split(" | ")];
                      console.log(tagsAndCategories);
                      tagsAndCategories.forEach((filter) => {
                        if (!filters.includes(filter)) {
                          filters.push(filter);
                        }
                      });
                      return filters;
                    }, [])
                    .map((filter) => (
                      <div
                        key={filter}
                        style={{
                          color: selectedFilters.includes(filter)
                            ? "#d1ae6e"
                            : "rgba(0,0,0,0.7)",
                          border: "none",
                          padding: "0px 15px",
                          borderRadius: "5px",
                          cursor: "pointer",
                          width: "100%",
                          marginBottom: "15px",
                          display: "flex",
                          fontWeight: selectedFilters.includes(filter)
                            ? "600"
                            : "400",
                          borderLeft: selectedFilters.includes(filter)
                            ? "2px solid #d1ae6e"
                            : "2px solid white",
                          transition: "all 0.4s ease-in-out",
                        }}
                        onClick={() => handleFilterChange(filter)}
                      >
                        {filter}
                      </div>
                    ))}

                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      marginBottom: "15px",
                    }}
                  >
                    Product Categories:
                  </div>
                  {allproduct
                    .reduce((filters, product) => {
                      const tags = product.Tag || ""; // Default to an empty string if tags is null
                      const categories = product.Category || ""; // Default to an empty string if categories is null

                      console.log(tags, categories);
                      const tagsAndCategories = [...categories.split(" | ")];
                      console.log(tagsAndCategories);
                      tagsAndCategories.forEach((filter) => {
                        if (!filters.includes(filter)) {
                          filters.push(filter);
                        }
                      });
                      return filters;
                    }, [])
                    .map((filter) => (
                      <div
                        key={filter}
                        style={{
                          color: selectedFilters.includes(filter)
                            ? "#d1ae6e"
                            : "rgba(0,0,0,0.7)",
                          border: "none",
                          padding: "0px 15px",
                          borderRadius: "5px",
                          cursor: "pointer",
                          width: "100%",
                          marginBottom: "15px",
                          display: "flex",
                          fontWeight: selectedFilters.includes(filter)
                            ? "600"
                            : "400",
                          borderLeft: selectedFilters.includes(filter)
                            ? "2px solid #d1ae6e"
                            : "2px solid white",
                          transition: "all 0.4s ease-in-out",
                        }}
                        onClick={() => handleFilterChange(filter)}
                      >
                        {filter}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div style={{ width: "80%", background: "#f0eeeb" }}>
              <div style={{ padding: "1.5%" }}>
                <input
                  className="search_input"
                  placeholder="Search all products"
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  style={{
                    padding: "12px 25px",
                    width: "95%",
                    border: "none",
                    background: "white",
                    borderRadius: "8px",
                    marginRight: "15px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    paddingTop: "10px",
                    height: "80vh",
                    overflowY: "scroll",
                  }}
                >
                  {searchedproducts.map((product, index) => (
                    <div
                      className="gc_button"
                      key={index}
                      style={{
                        width: screenHeight > screenWidth ? "22.5%" : "22.8%",
                        marginRight: "10px",
                        marginBottom: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        background: "white",
                        borderRadius: "12px",
                        textAlign: "center",
                        paddingBottom: "10px",
                      }}
                      onClick={() => handleproductClick(product)}
                    >
                      <div>
                        <img
                          src={product.productImage}
                          onError={(e) => {
                            e.target.src = fallbackImage;
                          }}
                          style={{
                            marginTop: "20px",
                            width: "140px",
                            height: "140px",
                            objectFit: "cover",
                            borderRadius: "12px",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          padding: "0px 20px",
                          fontSize: "18px",
                          color: "rgba(0,0,0,0.7)",
                          fontWeight: "600",
                        }}
                      >
                        {product.productName}
                      </div>
                      {/* <div
                        style={{
                          padding: "0px 20px",
                          fontSize: "18px",
                          color: "#d1ae6e",
                          fontWeight: "600",
                        }}
                      >
                        S$ {product.UnitPrice}
                      </div> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "97%",
              borderTop: "1px solid lightgrey",
              padding: "1.5%",
              height: "75vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{ color: "#d1ae6e", fontWeight: "600", fontSize: "50px" }}
            >
              Welcome.
            </div>
            <div>
              Let's start creating your client's cart, start by populating the
              field below
            </div>
            <form
              onSubmit={() => setEnteredName(true)}
              style={{ marginTop: "30px" }}
            >
              <input
                className="search_input"
                placeholder="Client Name"
                required
                onChange={(e) => {
                  setActiveClientName(e.target.value);
                  const clientIndex = clientData.findIndex(
                    (client) => client.name === e.target.value
                  );
                  if (clientIndex > -1) {
                    setMatchClientIndex(clientData[clientIndex].email);
                  } else {
                    setMatchClientIndex("");
                  }
                }}
                style={{
                  textAlign: "center",
                  padding: "10px 25px",
                  width: "350px",
                  fontSize: "20px",
                  borderRadius: "10px",
                }}
              />
              {matchClientIndex.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginRight: "5px" }}>
                    Customer exist under{" "}
                  </div>
                  <div style={{ color: "green" }}>{matchClientIndex}</div>
                </div>
              ) : (
                <></>
              )}
              <div style={{ textAlign: "center" }}>
                <button
                  style={{
                    background: "#d1ae6e",
                    padding: "8px 25px",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: "10px",
                    border: "none",
                    outline: "none",
                    fontSize: "18px",
                    marginTop: "20px",
                  }}
                  type="submit"
                >
                  {matchClientIndex.length > 0 ? "Create Cart" : "Create Cart"}
                </button>
              </div>
            </form>
          </div>
        )
      ) : (
        <div
          style={{
            overflowY: "auto",
            height: "93vh",
            borderTop: "1px solid lightgrey",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              padding: "2%",
            }}
          >
            {searchedCart.map((cart, index) => (
              <div
                className="gc_button"
                key={index}
                style={{
                  width:
                    screenWidth > screenHeight
                      ? "calc(19.3% - 20px)"
                      : "calc(24% - 20px)",
                  height: "200px",
                  margin: "10px",
                  background: "#282828",
                  borderRadius: "14px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "30px 0px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setActiveid(cart.id);
                  setCartOpen(true);
                  setEnteredName(true);
                  setActiveClientName(cart.name);
                  setActiveClientCart(cart.product);
                }}
              >
                <div
                  style={{
                    fontWeight: "800",
                    fontSize: "25px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    width: "60px",
                    height: "60px",
                    background: "lightgrey",
                    borderRadius: "50%",
                    color: "#282828",
                  }}
                >
                  {cart.name
                    .split(" ")
                    .slice(0, 2)
                    .map((word) => word.charAt(0))
                    .join("")
                    .toUpperCase()}
                </div>
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: "25px",
                    color: "#d1ae6e",
                    marginTop: "10px",
                  }}
                >
                  {cart.name}
                </div>
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "15px",
                    color: "#d1ae6e",
                    marginTop: "5px",
                  }}
                >
                  {formatDate(cart.updated_at)}
                </div>
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: "18px",
                    background: "#d1ae6e",
                    marginTop: "15px",
                    color: "#282828",
                    padding: "8px 25px",
                    borderRadius: "10px",
                    display: "flex",
                  }}
                >
                  <div style={{ marginRight: "5px" }}>
                    {cart.product.reduce((totalQuantity, cartItem) => {
                      return totalQuantity + cartItem.quantity;
                    }, 0)}
                  </div>
                  Items in Cart
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
