import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { MarginTwoTone } from "@mui/icons-material";
import ProductSampleImage from "./img/gc_icon.png";
import CSVReader from "react-csv-reader";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-modal";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Cookies from "js-cookie";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import axios from "axios";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { CircularProgress } from "@mui/material";
import fallbackImage from "./img/7fd2cdd1-31a7-4810-bf03-137aaea45f12.jpeg";

const Product = () => {
  const today = new Date();
  const formattedDate = today.toISOString().substr(0, 10).replace(/-/g, "");

  function generateRandomUuid() {
    return Math.random().toString(36).substr(2, 5);
  }
  const fileInputRef = useRef(null);
  const [allProduct, setAllProduct] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [screenLoading, setScreenLoading] = useState(false);

  const [isFormVisible, setIsFormVisible] = useState(false);

  const [fileUploading, setFileUploading] = useState(false);

  const [newProductData, setNewProductData] = useState({
    name: "",
    description: "",
    sku: "",
    unit_price: "",
    weight: "",
    length: "",
    width: "",
    height: "",
    categories: "",
    tags: "",
    brand: "",
    luminance: "",
    size: "",
    stock: "",
  });

  const handleImageUpload = async (e) => {
    setFileUploading(true);
    const file = e.target.files;
    console.log(e.target.files);

    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append("files[]", file[i]);
    }

    try {
      const response = await axios.post(
        "https://www.sales.gclightinggallery.com/laravel/api/upload-image",
        formData
      );
      const imageUrl = response.data.urls;
      const updatedProduct = { ...selectedProduct };
      updatedProduct.img_src = imageUrl[0];
      console.log(imageUrl[0]);
      setSelectedProduct(updatedProduct);
      setFileUploading(false);
    } catch (error) {
      console.log(error);
      setFileUploading(false);
    }
  };

  const handleClick = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  const handleNewProductImageUpload = async (e) => {
    setFileUploading(true);
    const file = e.target.files;
    console.log(e.target.files);

    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append("files[]", file[i]);
    }

    try {
      const response = await axios.post(
        "https://www.sales.gclightinggallery.com/laravel/api/upload-image",
        formData
      );
      const imageUrl = response.data.urls;
      const updatedProduct = { ...newProductData };
      updatedProduct.img_src = imageUrl[0];
      console.log(imageUrl[0]);
      setNewProductData(updatedProduct);
      setFileUploading(false);
    } catch (error) {
      console.log(error);
      setFileUploading(false);
    }
  };

  const handleNewProductClick = () => {
    const fileInput = document.getElementById("imageNewProductInput");
    fileInput.click();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedProduct({ ...selectedProduct, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const index = allProduct.findIndex(
      (product) => product.sku === selectedProduct.sku
    );
    console.log(index);

    const updatedAllProduct = [...allProduct];
    if (index !== -1) {
      updatedAllProduct[index] = selectedProduct;
      setAllProduct(updatedAllProduct);
      console.log(updatedAllProduct);
    }

    try {
      const response = await fetch(
        "https://www.sales.gclightinggallery.com/laravel/api/createandupdateproducts",
        {
          body: JSON.stringify({
            product_data: updatedAllProduct,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
        }
      );
      if (!response.ok) {
        // setSignUpError(true);
        throw new Error("Network response was not ok");
      }

      // Clear the form data after adding the product
    } catch (error) {
      // setSignUpError(true);
      console.error("Error signing up:", error);
    }

    setIsFormVisible(false);

    console.log(
      "handle submit for updating products",
      updatedAllProduct[index]
    );
  };

  const [selectedFilters, setSelectedFilters] = useState([]);

  const getProducts = async () => {
    try {
      const response = await fetch(
        "https://www.sales.gclightinggallery.com/laravel/api/getproducts",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const gottenProducts = await getProducts();
      if (gottenProducts) {
        console.log(gottenProducts.product);
        setAllProduct(gottenProducts.product);
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchProducts();
  }, []);

  // Function to handle changes in selected filters
  const handleFilterChange = (filter) => {
    if (selectedFilters.includes(filter)) {
      // Filter is already selected, remove it
      setSelectedFilters(selectedFilters.filter((f) => f !== filter));
    } else {
      // Filter is not selected, add it
      setSelectedFilters([...selectedFilters, filter]);
    }
  };

  // Function to filter the products based on selected filters
  const filteredProducts = allProduct.filter((product) => {
    const tags = product.tags || ""; // Default to an empty string if tags is null
    const categories = product.categories || ""; // Default to an empty string if categories is null

    const tagssAndCategories = [
      ...tags.split(" | "),
      ...categories.split(" | "),
    ];
    // console.log(selectedFilters);

    return selectedFilters.length === 0
      ? true // Return true if no filters are selected
      : selectedFilters.some((filter) => tagssAndCategories.includes(filter));
  });

  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Function to filter invoices based on search query
  const searchedProducts = filteredProducts.filter((product) => {
    const { description, name, sku } = product;
    const lowerCaseSearchQuery = searchQuery.toLowerCase();

    return (
      (description &&
        description.toLowerCase().includes(lowerCaseSearchQuery)) ||
      (name && name.toLowerCase().includes(lowerCaseSearchQuery)) ||
      (sku && sku.toLowerCase().includes(lowerCaseSearchQuery))
    );
  });

  const [importedData, setImportedData] = useState([]);

  const handleForce = (data) => {
    console.log(data);
    setImportedData(data);
    setModalIsOpen(true);
  };

  const handleProcessImportedData = (data) => {
    if (data && data.length > 0) {
      processCsvData(data);
    }
  };

  const token = Cookies.get("token");
  const processCsvData = async (importedProducts) => {
    setScreenLoading(true);
    // Define the maximum chunk size
    const chunkSize = 10;

    // Create a new array to hold the updated products
    const updatedAllProduct = [...allProduct];

    // Split the imported products into chunks
    for (let i = 0; i < importedProducts.length; i += chunkSize) {
      const chunk = importedProducts.slice(i, i + chunkSize);

      // Iterate over the chunk of products
      chunk.forEach((importedProduct) => {
        // Check if the sku of the imported product already exists in the allProduct array
        const existingProductIndex = updatedAllProduct.findIndex(
          (product) => product.sku === importedProduct.sku
        );

        if (importedProduct.width === null) {
          console.log("the product", importedProduct.sku, "is null on name");
        }

        // If the product with the same sku exists, update its properties
        if (existingProductIndex !== -1) {
          updatedAllProduct[existingProductIndex] = {
            ...updatedAllProduct[existingProductIndex],
            ...importedProduct,
          };
        } else {
          // If the product with the same sku does not exist, add it as a new product
          updatedAllProduct.push(importedProduct);
          console.log("product being pushed");
        }
      });

      // Make an API call for the current chunk of products
      await updateProductsViaAPI(chunk);
    }

    // Update the state with the new array of products
    setAllProduct(updatedAllProduct);

    console.log(updatedAllProduct);
    setScreenLoading(false);
  };

  const updateProductsViaAPI = async (products) => {
    try {
      const response = await fetch(
        "https://www.sales.gclightinggallery.com/laravel/api/createandupdateproducts",
        {
          body: JSON.stringify({
            product_data: products,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
        }
      );
      if (!response.ok) {
        // Handle API error here
        console.error("API Error:", response.statusText);
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      // Handle error
      console.error("Error updating products:", error);
    }
  };

  const handleImportButtonClick = () => {
    // Trigger the file input when the custom import button is clicked
    fileInputRef.current.click();
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addProductModalIsOpen, setAddProductModalIsOpen] = useState(false);

  const handleNewProductInputChange = (e) => {
    const { name, value } = e.target;
    setNewProductData({ ...newProductData, [name]: value });
  };

  const handleAddNewProduct = async (e) => {
    e.preventDefault();
    const sendingProductData = { ...newProductData };
    sendingProductData.sku = sendingProductData.name;
    setNewProductData(sendingProductData);
    // Add the new product to the allProduct array

    try {
      const response = await fetch(
        "https://www.sales.gclightinggallery.com/laravel/api/createandupdateproducts",
        {
          body: JSON.stringify({
            product_data: [sendingProductData],
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
        }
      );
      if (!response.ok) {
        // setSignUpError(true);
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      console.log(json.updated_products);
      setAllProduct([...allProduct, json.updated_products[0]]);
      // Clear the form data after adding the product
    } catch (error) {
      // setSignUpError(true);
      console.error("Error signing up:", error);
    }

    // Clear the form data after adding the product
    setNewProductData({
      name: "",
      description: "",
      sku: "",
      unit_price: "",
      weight: "",
      length: "",
      width: "",
      height: "",
      categories: "",
      tags: "",
      brand: "",
      luminance: "",
      size: "",
      stock: "",
    });
    setAddProductModalIsOpen(false);
  };

  const [deleteIsOpen, setDeleteIsOpen] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      {screenLoading ? (
        <div
          style={{
            position: "fixed",
            width: "87vw",
            height: "100vh",
            background: "rgba(0,0,0,0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            color="primary"
            size={100}
            style={{ color: "white" }}
          />
        </div>
      ) : (
        <></>
      )}
      <Modal
        isOpen={deleteIsOpen}
        ariaHideApp={false}
        onRequestClose={() => {
          setDeleteIsOpen(false);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "600px",
            height: "300px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            boxShadow: "none",
            borderRadius: "10px",
            border: "none",
            overflowX: "hidden",
            overflowY: "auto",
            padding: "none",
            display: "flex",
            textAlign: "center",
          },
        }}
      >
        <div
          style={{
            fontSize: "35px",
            color: "#d1ae6e",
            fontWeight: "600",
            marginBottom: "20px",
          }}
        >
          Confirm delete product?
        </div>
        <div style={{ textAlign: "center" }}>
          <button
            style={{
              background: "white",
              padding: "8px 25px",
              color: "#d1ae6e",
              cursor: "pointer",
              borderRadius: "10px",
              border: "1px solid #d1ae6e",
              outline: "none",
              fontSize: "18px",
              marginRight: "10px",
            }}
            onClick={() => {
              setDeleteIsOpen(false);
            }}
          >
            Cancel
          </button>
          <button
            style={{
              background: "#d1ae6e",
              padding: "8px 25px",
              color: "white",
              cursor: "pointer",
              borderRadius: "10px",
              border: "none",
              outline: "none",
              fontSize: "18px",
            }}
            onClick={async () => {
              console.log(selectedProduct.sku);
              const productIndex = allProduct.findIndex(
                (finding) => finding.sku === selectedProduct.sku
              );
              console.log(productIndex);
              const changingProduct = [...allProduct];
              const token = Cookies.get("token");

              try {
                const response = await axios.delete(
                  `https://www.sales.gclightinggallery.com/laravel/api/deleteproducts/${selectedProduct.id}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );

                changingProduct.splice(productIndex, 1);
                setAllProduct(changingProduct);
                setDeleteIsOpen(false);
                setIsFormVisible(false);
              } catch (error) {
                console.log(error);
                setAllProduct(changingProduct);
                setDeleteIsOpen(false);
                setIsFormVisible(false);
              }
            }}
          >
            Delete Product
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={() => {
          setModalIsOpen(false);
          fileInputRef.current.value = "";
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            height: "90%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            boxShadow: "none",
            borderRadius: "10px",
            border: "none",
            overflowX: "hidden",
            overflowY: "auto",
            padding: "none",
          },
        }}
      >
        <div
          style={{
            width: "100%",
            height: "90%",
          }}
        >
          <div
            style={{
              fontSize: "30px",
              fontweight: "600",
              color: "rgba(0,0,0,0.7)",
              borderBottom: "1px solid lightgrey",
              padding: "10px 0px",
              width: "97%",
              marginLeft: "1.5%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ marginLeft: "10px" }}>Product Import List</div>
            <div style={{ marginRight: "10px" }}>
              <CloseIcon
                style={{ fontSize: "28px", cursor: "pointer" }}
                onClick={() => {
                  setModalIsOpen(false);
                  fileInputRef.current.value = "";
                }}
              />
            </div>
          </div>
          {importedData.length > 0 ? (
            <div
              style={{
                width: "97%",
                padding: "0 1.5%",
                display: "flex",
                flexDirection: "column",
                height: "calc(100% - 100px)",
                overflowY: "scroll",
              }}
            >
              {importedData.map((product, index) => (
                <div
                  key={index}
                  className="invoice_item"
                  style={{
                    borderBottom: "1px solid lightgrey",
                    padding: "20px",
                    height: "auto",
                  }}
                >
                  <div
                    style={{
                      //   background: "rgba(256,256,256,0.8)",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "auto",
                        paddingRight: "1%",
                      }}
                    >
                      <img
                        src={product.img_src}
                        onError={(e) => {
                          e.target.src = fallbackImage; 
                        }}
                        style={{
                          height: "80px",
                          width: "80px",
                          objectFit: "cover",
                          border: "1px solid lightgrey",
                          borderRadius: "10px",
                          marginRight: "5px",
                        }}
                      />
                    </div>
                    <div style={{ width: "90%", paddingRight: "1%" }}>
                      <div
                        style={{
                          fontweight: "600",
                          color: "#d1ae6e",
                          fontSize: "20px",
                        }}
                      >
                        {product.name}
                      </div>
                      <div
                        style={{ fontweight: "400", color: "rgba(0,0,0,0.6)" }}
                      >
                        {product.description}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "24%",
                        paddingRight: "1%",
                        display: "flex",
                      }}
                    >
                      {product.stock > 10 ? (
                        <div style={{ color: "#0d8c22", fontweight: "600" }}>
                          In stock
                        </div>
                      ) : product.stock <= 10 && product.stock > 0 ? (
                        <div style={{ color: "#e62e2e", fontweight: "600" }}>
                          Low stock
                        </div>
                      ) : product.stock === 0 ? (
                        <div style={{ color: "#e62e2e", fontweight: "600" }}>
                          No stock
                        </div>
                      ) : (
                        <></>
                      )}

                      <div style={{ marginLeft: "5px" }}>({product.stock})</div>
                    </div>
                    <div style={{ width: "24%", paddingRight: "1%" }}>
                      {product.categories}
                    </div>

                    <div style={{ width: "24%", paddingRight: "1%" }}>
                      {product.tags}
                    </div>
                    <div
                      style={{
                        width: "24%",
                        paddingRight: "1%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          background: product.luminance,
                          borderRadius: "50%",
                          marginRight: "5px",
                          boxShadow: "0px 0px 8px rgba(0,0,0,0.2)",
                        }}
                      ></div>
                      <div>{product.luminance}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>no data</>
          )}
        </div>
        <div style={{ textAlign: "center" }}>
          <button
            style={{
              background: "white",
              padding: "8px 25px",
              color: "#d1ae6e",
              cursor: "pointer",
              borderRadius: "10px",
              border: "1px solid #d1ae6e",
              outline: "none",
              fontSize: "18px",
              marginRight: "10px",
            }}
            onClick={() => {
              setModalIsOpen(false);
              fileInputRef.current.value = "";
            }}
          >
            Cancel
          </button>
          <button
            style={{
              background: "#d1ae6e",
              padding: "8px 25px",
              color: "white",
              cursor: "pointer",
              borderRadius: "10px",
              border: "none",
              outline: "none",
              fontSize: "18px",
            }}
            onClick={() => {
              handleProcessImportedData(importedData);
              setModalIsOpen(false);
              fileInputRef.current.value = "";
            }}
          >
            Import
          </button>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={addProductModalIsOpen}
        onRequestClose={() => {
          setAddProductModalIsOpen(false);
          setNewProductData({
            name: "",
            description: "",
            sku: "",
            unit_price: "",
            weight: "",
            length: "",
            width: "",
            height: "",
            categories: "",
            tags: "",
            brand: "",
            luminance: "",
            size: "",
            stock: "",
          });
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "700px",
            height: "800px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            boxShadow: "none",
            borderRadius: "10px",
            border: "none",
            overflowX: "hidden",
            overflowY: "auto",
            padding: "none",
          },
        }}
      >
        <div
          style={{
            fontSize: "25px",
            fontweight: "600",
            color: "rgba(0,0,0,0.7)",
            borderBottom: "1px solid lightgrey",
            padding: "10px 0px",
            width: "97%",
            marginLeft: "1.5%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{ marginLeft: "10px", fontSize: "25px", fontweight: "600" }}
          >
            New Product
          </div>
          <div style={{ marginRight: "10px" }}>
            <CloseIcon
              style={{ fontSize: "28px", cursor: "pointer" }}
              onClick={() => {
                setAddProductModalIsOpen(false);
                setNewProductData({
                  name: "",
                  description: "",
                  sku: "",
                  unit_price: "",
                  weight: "",
                  length: "",
                  width: "",
                  height: "",
                  categories: "",
                  tags: "",
                  brand: "",
                  luminance: "",
                  size: "",
                  stock: "",
                });
              }}
            />
          </div>
        </div>
        <div style={{ width: "94%", padding: "0 3%" }}>
          <form onSubmit={handleAddNewProduct}>
            <div
              style={{ marginRight: "25px", marginTop: "20px" }}
              onClick={handleNewProductClick}
            >
              <input
                id="imageNewProductInput"
                type="file"
                style={{ display: "none" }}
                onChange={handleNewProductImageUpload}
              />
              {fileUploading ? (
                <div
                  style={{
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                    borderRadius: "10px",
                    width: "180px",
                    height: "180px",
                    objectFit: "cover",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress color="primary" size={50} style={{}} />
                </div>
              ) : newProductData.img_src ? (
                <img
                  src={newProductData.img_src}
                  onError={(e) => {
                    e.target.src = fallbackImage; 
                  }}
                  style={{
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                    borderRadius: "10px",
                    width: "180px",
                    height: "180px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <div
                  style={{
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                    borderRadius: "10px",
                    width: "180px",
                    height: "180px",
                    objectFit: "cover",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FileUploadIcon
                    style={{ color: "lightgrey", fontSize: "50px" }}
                  />
                </div>
              )}
            </div>
            <div style={{ width: "100%", marginTop: "10px" }}>
              <div>
                <input
                  required
                  type="text"
                  name="name"
                  value={newProductData.name}
                  onChange={handleNewProductInputChange}
                  placeholder="Product Name"
                  style={{
                    fontSize: "18px",
                    background: "none",
                    border: "1px solid lightgrey",
                    outline: "none",
                    borderRadius: "10px",
                    width: "100%",
                    color: "rgba(0,0,0,0.7)",
                    fontweight: "600",
                    padding: "5px",
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    width: "50%",
                  }}
                >
                  <div style={{ marginRight: "5px" }}>SKU: </div>
                  <input
                    required
                    type="text"
                    name="sku"
                    placeholder="LB0123456789"
                    value={newProductData.sku}
                    onChange={handleNewProductInputChange}
                    style={{
                      fontSize: "18px",
                      background: "none",
                      border: "1px solid lightgrey",
                      outline: "none",
                      borderRadius: "10px",
                      width: "200px",
                      color: "rgba(0,0,0,0.7)",
                      marginTop: "15px",
                      fontweight: "600",
                      padding: "5px 10px",
                    }}
                  />
                </div> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    width: "50%",
                  }}
                >
                  <div style={{ marginRight: "5px" }}>SGD </div>
                  <input
                    required
                    type="text"
                    name="unit_price"
                    placeholder="0.00"
                    value={newProductData.unit_price}
                    onChange={handleNewProductInputChange}
                    style={{
                      fontSize: "18px",
                      background: "none",
                      border: "1px solid lightgrey",
                      outline: "none",
                      borderRadius: "10px",
                      width: "100px",
                      color: "rgba(0,0,0,0.7)",
                      marginTop: "15px",
                      fontweight: "600",
                      padding: "5px 10px",
                    }}
                  />
                </div>
              </div>
              <div>
                <textarea
                  required
                  type="text"
                  name="description"
                  placeholder="Type your product description here."
                  value={newProductData.description}
                  onChange={handleNewProductInputChange}
                  style={{
                    fontSize: "15px",
                    background: "none",
                    border: "1px solid lightgrey",
                    outline: "none",
                    borderRadius: "10px",
                    width: "100%",
                    color: "rgba(0,0,0,0.7)",
                    fontweight: "400",
                    marginTop: "15px",
                    height: "120px",
                    padding: "5px",
                  }}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "5px", width: "180px" }}>Stock:</div>
                <input
                  type="text"
                  name="stock"
                  placeholder=""
                  value={newProductData.stock}
                  onChange={handleNewProductInputChange}
                  style={{
                    fontSize: "15px",
                    background: "none",
                    border: "1px solid lightgrey",
                    outline: "none",
                    borderRadius: "10px",
                    width: "100px",
                    color: "rgba(0,0,0,0.7)",
                    fontweight: "400",
                    marginTop: "15px",
                    padding: "5px",
                    marginRight: "5px",
                  }}
                />
                {newProductData.stock ? (
                  <div
                    className="blink"
                    style={{
                      width: "10px",
                      height: "10px",
                      background: newProductData.stock < 10 ? "red" : "#0d8c22",
                      borderRadius: "50%",
                      boxShadow:
                        newProductData.stock < 10
                          ? "0px 0px 8px red"
                          : "0px 0px 8px #0d8c22",
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "5px", width: "180px" }}>
                  Color/Luminance:
                </div>
                <select
                  type="text"
                  name="luminance"
                  value={newProductData.luminance}
                  onChange={handleNewProductInputChange}
                  style={{
                    fontSize: "15px",
                    background: "none",
                    border: "1px solid lightgrey",
                    outline: "none",
                    borderRadius: "10px",
                    width: "250px",
                    color: "rgba(0,0,0,0.7)",
                    fontweight: "400",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                >
                  <option value="None">None</option>
                  <option value="Blue">9,000 K</option>
                  <option value="Lightblue">6,500 K</option>
                  <option value="white">5,000 K</option>
                  <option value="Lightyellow">4,100 K</option>
                  <option value="Yellow">3,000 K</option>
                </select>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "5px", width: "180px" }}>
                  Categories:
                </div>
                <input
                  type="text"
                  name="categories"
                  placeholder="Input categories to ease search results"
                  value={newProductData.categories}
                  onChange={handleNewProductInputChange}
                  style={{
                    fontSize: "15px",
                    background: "none",
                    border: "1px solid lightgrey",
                    outline: "none",
                    borderRadius: "10px",
                    width: "600px",
                    color: "rgba(0,0,0,0.7)",
                    fontweight: "400",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "5px", width: "180px" }}>Tags:</div>
                <input
                  type="text"
                  name="tags"
                  placeholder="tags your product to ease search results"
                  value={newProductData.tags}
                  onChange={handleNewProductInputChange}
                  style={{
                    fontSize: "15px",
                    background: "none",
                    border: "1px solid lightgrey",
                    outline: "none",
                    borderRadius: "10px",
                    width: "600px",
                    color: "rgba(0,0,0,0.7)",
                    fontweight: "400",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                />
              </div>
            </div>

            <button
              type="submit"
              style={{
                background: "#d1ae6e",
                padding: "8px 25px",
                color: "white",
                cursor: "pointer",
                borderRadius: "10px",
                border: "none",
                outline: "none",
                fontSize: "18px",
                marginTop: "40px",
              }}
            >
              Add Product
            </button>
          </form>
        </div>
      </Modal>

      <div
        style={{
          padding: "0px 20px",
          height: "70px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: isFormVisible ? "30px" : "0px",
            marginTop: "7px",
            opacity: isFormVisible ? "1" : "0",
            transition: "all 0.4s ease-in-out",
            cursor: isFormVisible ? "pointer" : "none",
          }}
          onClick={() => setIsFormVisible(false)}
        >
          <ArrowBackIosNewIcon />
        </div>
        <div
          style={{
            margin: "0px",
            fontSize: "32PX",
            fontWeight: "700",
            marginRight: "10px",
            cursor: "pointer",
          }}
          onClick={() => setIsFormVisible(false)}
        >
          Products
        </div>
        <div
          style={{
            background: "#d1ae6e",
            borderRadius: "7px",
            display: "flex",
            alignItems: "center",
            padding: "6px 15px 6px 10px",
            cursor: "pointer",
            marginRight: "5px",
            marginTop: "5px",
          }}
          onClick={() => setAddProductModalIsOpen(true)}
        >
          <AddIcon style={{ color: "white", fontSize: "18px" }} />
          <div
            style={{
              color: "white",
              fontSize: "13px",
              marginBottom: "2.5px",
            }}
          >
            Add New
          </div>
        </div>
        <div
          style={{
            background: "#d1ae6e",
            borderRadius: "7px",
            display: "flex",
            alignItems: "center",
            padding: "6px 15px 6px 10px",
            cursor: "pointer",
            marginRight: "5px",
            marginTop: "5px",
          }}
          onClick={handleImportButtonClick}
        >
          <AddIcon style={{ color: "white", fontSize: "18px" }} />
          <div
            style={{
              color: "white",
              fontSize: "13px",
              marginBottom: "2.5px",
            }}
          >
            Import Products
          </div>
        </div>
        {/* <div
          style={{
            background: "#d1ae6e",
            borderRadius: "7px",
            display: "flex",
            alignItems: "center",
            padding: "6px 15px 6px 10px",
            cursor: "pointer",
            marginRight: "5px",
            marginTop: "5px",
          }}
        >
          <AddIcon style={{ color: "white", fontSize: "18px" }} />
          <div
            style={{
              color: "white",
              fontSize: "13px",
              marginBottom: "2.5px",
            }}
          >
            Export Products
          </div>
        </div> */}
        <div style={{ display: "none" }}>
          <CSVReader
            ref={fileInputRef}
            cssClass="react-csv-input"
            label="Select CSV File"
            onFileLoaded={handleForce}
            parserOptions={{
              header: true,
              skipEmptyLines: true,
            }}
          />
        </div>
      </div>

      {isFormVisible ? (
        <></>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              width: "calc(100% - 40px)",
              borderTop: "1px solid lightgrey",
              padding: "5px 20px",
              height: "30px",
              alignItems: "center",
            }}
          >
            <input
              className="search_input"
              placeholder="Search all Products"
              type="text"
              value={searchQuery}
              onChange={handleSearchInputChange}
              style={{
                padding: "8px 20px",
                width: "400px",
                border: "none",
                background: "#f0eeeb",
                borderRadius: "8px",
                marginRight: "15px",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                setSearchQuery("");
                setSelectedFilters([]);
                setIsFormVisible(false);
              }}
            >
              <div style={{ marginRight: "3px", color: "#d1ae6e" }}>All</div> (
              {allProduct.length})
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "calc(100% - 40px)",
              borderTop: "1px solid lightgrey",
              padding: "5px 20px",
              alignItems: "center",
            }}
          >
            <div style={{ fontWeight: 700 }}>Product Tags:</div>
            <div>
              {allProduct
                .reduce((filters, product) => {
                  const tags = product.tags || ""; // Default to an empty string if tags is null
                  const categories = product.categories || ""; // Default to an empty string if categories is null

                  const tagssAndCategories = [...tags.split(" | ")];
                  // console.log(selectedFilters);
                  tagssAndCategories.forEach((filter) => {
                    if (!filters.includes(filter)) {
                      filters.push(filter);
                    }
                  });
                  return filters;
                }, [])
                .map((filter) => (
                  <button
                    key={filter}
                    style={{
                      margin: "0 2px",
                      background: selectedFilters.includes(filter)
                        ? "#d1ae6e"
                        : "white",
                      color: selectedFilters.includes(filter)
                        ? "white"
                        : "rgba(0,0,0,0.7)",
                      border: "none",
                      padding: "5px 15px",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleFilterChange(filter)}
                  >
                    {filter}
                  </button>
                ))}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "calc(100% - 40px)",
              borderTop: "1px solid lightgrey",
              padding: "5px 20px",
              alignItems: "center",
            }}
          >
            <div style={{ fontWeight: 700 }}>Product Categories:</div>
            <div style={{ overflowX: "scroll", display: "flex" }}>
              {allProduct
                .reduce((filters, product) => {
                  const tags = product.tags || ""; // Default to an empty string if tags is null
                  const categories = product.categories || ""; // Default to an empty string if categories is null

                  const tagssAndCategories = [...categories.split(" | ")];
                  // console.log(selectedFilters);
                  tagssAndCategories.forEach((filter) => {
                    if (!filters.includes(filter)) {
                      filters.push(filter);
                    }
                  });
                  return filters;
                }, [])
                .map((filter) => (
                  <button
                    key={filter}
                    style={{
                      margin: "0 2px",
                      background: selectedFilters.includes(filter)
                        ? "#d1ae6e"
                        : "white",
                      color: selectedFilters.includes(filter)
                        ? "white"
                        : "rgba(0,0,0,0.7)",
                      border: "none",
                      padding: "5px 15px",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleFilterChange(filter)}
                  >
                    {filter}
                  </button>
                ))}
            </div>
          </div>
        </>
      )}
      {/* <div>{selectedFilters}</div> */}

      {isFormVisible ? (
        <div
          style={{
            width: "100%",
            borderTop: "1px solid lightgrey",
            padding: "20px",
            background: "#f7f6f5",
            height: "calc(100vh - 100px)",
            overflowY: "scroll",
          }}
        >
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div
              style={{
                color: "#d1ae6e",
                fontSize: "25px",
                fontweight: "600",
                marginRight: "15px",
              }}
            >
              {selectedProduct.name}
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.4)",
                fontSize: "15px",
                fontweight: "400",
              }}
            >
              {selectedProduct.sku}
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                width: "calc(98% - 40px)",
                marginTop: "25px",
              }}
            >
              <div style={{ marginRight: "25px" }} onClick={handleClick}>
                <input
                  id="imageInput"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
                {fileUploading ? (
                  <div
                    style={{
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                      borderRadius: "10px",
                      width: "120px",
                      height: "120px",
                      objectFit: "cover",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress color="primary" size={50} style={{}} />
                  </div>
                ) : selectedProduct.img_src ? (
                  <img
                    src={selectedProduct.img_src}
                    onError={(e) => {
                      e.target.src = fallbackImage; 
                    }}
                    style={{
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                      borderRadius: "10px",
                      width: "120px",
                      height: "120px",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                      borderRadius: "10px",
                      width: "120px",
                      height: "120px",
                      objectFit: "cover",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FileUploadIcon
                      style={{ color: "lightgrey", fontSize: "50px" }}
                    />
                  </div>
                )}
              </div>
              <div style={{ width: "100%" }}>
                <div>
                  <input
                    type="text"
                    name="name"
                    value={selectedProduct.name}
                    onChange={handleChange}
                    style={{
                      fontSize: "40px",
                      background: "none",
                      border: "1px solid lightgrey",
                      outline: "none",
                      borderRadius: "10px",
                      width: "100%",
                      color: "rgba(0,0,0,0.7)",
                      fontweight: "600",
                      padding: "5px",
                    }}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <div style={{ marginRight: "5px" }}>SGD </div>
                  <input
                    type="text"
                    name="unit_price"
                    value={selectedProduct.unit_price}
                    onChange={handleChange}
                    style={{
                      fontSize: "30px",
                      background: "none",
                      border: "1px solid lightgrey",
                      outline: "none",
                      borderRadius: "10px",
                      width: "100px",
                      color: "rgba(0,0,0,0.7)",
                      marginTop: "15px",
                      fontweight: "600",
                      padding: "5px 10px",
                    }}
                  />
                </div>
                <div>
                  <textarea
                    type="text"
                    name="description"
                    value={selectedProduct.description}
                    onChange={handleChange}
                    style={{
                      fontSize: "15px",
                      background: "none",
                      border: "1px solid lightgrey",
                      outline: "none",
                      borderRadius: "10px",
                      width: "100%",
                      color: "rgba(0,0,0,0.7)",
                      fontweight: "400",
                      marginTop: "15px",
                      height: "210px",
                      padding: "5px",
                    }}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: "5px", width: "180px" }}>
                    Stock:
                  </div>
                  <input
                    type="text"
                    name="stock"
                    value={selectedProduct.stock}
                    onChange={handleChange}
                    style={{
                      fontSize: "15px",
                      background: "none",
                      border: "1px solid lightgrey",
                      outline: "none",
                      borderRadius: "10px",
                      width: "100px",
                      color: "rgba(0,0,0,0.7)",
                      fontweight: "400",
                      marginTop: "15px",
                      padding: "5px",
                      marginRight: "5px",
                    }}
                  />
                  <div
                    className="blink"
                    style={{
                      width: "10px",
                      height: "10px",
                      background:
                        selectedProduct.stock < 10 ? "red" : "#0d8c22",
                      borderRadius: "50%",
                      boxShadow:
                        selectedProduct.stock < 10
                          ? "0px 0px 8px red"
                          : "0px 0px 8px #0d8c22",
                    }}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: "5px", width: "180px" }}>
                    Color/Luminance:
                  </div>
                  <select
                    type="text"
                    name="luminance"
                    value={selectedProduct.luminance}
                    onChange={handleChange}
                    style={{
                      fontSize: "15px",
                      background: "none",
                      border: "1px solid lightgrey",
                      outline: "none",
                      borderRadius: "10px",
                      width: "250px",
                      color: "rgba(0,0,0,0.7)",
                      fontweight: "400",
                      marginTop: "15px",
                      padding: "5px",
                    }}
                  >
                    <option value="None">None</option>
                    <option value="Blue">9,000 K</option>
                    <option value="Lightblue">6,500 K</option>
                    <option value="white">5,000 K</option>
                    <option value="Lightyellow">4,100 K</option>
                    <option value="Yellow">3,000 K</option>
                  </select>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: "5px", width: "180px" }}>
                    Categories:
                  </div>
                  <input
                    type="text"
                    name="categories"
                    value={selectedProduct.categories}
                    onChange={handleChange}
                    style={{
                      fontSize: "15px",
                      background: "none",
                      border: "1px solid lightgrey",
                      outline: "none",
                      borderRadius: "10px",
                      width: "600px",
                      color: "rgba(0,0,0,0.7)",
                      fontweight: "400",
                      marginTop: "15px",
                      padding: "5px",
                    }}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: "5px", width: "180px" }}>
                    Tags:
                  </div>
                  <input
                    type="text"
                    name="tags"
                    value={selectedProduct.tags}
                    onChange={handleChange}
                    style={{
                      fontSize: "15px",
                      background: "none",
                      border: "1px solid lightgrey",
                      outline: "none",
                      borderRadius: "10px",
                      width: "600px",
                      color: "rgba(0,0,0,0.7)",
                      fontweight: "400",
                      marginTop: "15px",
                      padding: "5px",
                    }}
                  />
                </div>
                <button
                  style={{
                    border: "1.5px solid #d1ae6e",
                    color: "#d1ae6e",
                    padding: "8px 25px",
                    cursor: "pointer",
                    borderRadius: "10px",
                    outline: "none",
                    fontSize: "18px",
                    marginTop: "40px",
                    marginRight: "10px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteIsOpen(true);
                  }}
                >
                  Delete
                </button>
                <button
                  type="submit"
                  style={{
                    background: "#d1ae6e",
                    padding: "8px 25px",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: "10px",
                    border: "none",
                    outline: "none",
                    fontSize: "18px",
                    marginTop: "40px",
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            borderTop: "1px solid lightgrey",
            padding: "15px",
            background: "#f7f6f5",
            height: "calc(100vh - 270px)",
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              border: "1px solid lightgrey",
              width: "calc(98% - 30px)",
              height: "35px",
              background: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 1%",
            }}
          >
            <div style={{ width: "10%" }}>Product</div>
            <div style={{ width: "39%" }}>Product Description</div>
            <div style={{ width: "16%" }}>SKU</div>
            <div style={{ width: "16%" }}>Stock Status</div>
            <div style={{ width: "10%" }}>Categories</div>
            <div style={{ width: "10%" }}>Tags</div>
          </div>
          {searchedProducts.map((product, index) => (
            <div
              className="invoice_item"
              key={index}
              style={{
                borderLeft: "1px solid lightgrey",
                borderBottom: "1px solid lightgrey",
                borderRight: "1px solid lightgrey",
                width: "calc(98% - 30px)",
                background: "rgba(256,256,256,0.8)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px 1%",
              }}
              onClick={() => {
                setSelectedProduct(product);
                setIsFormVisible(true);
              }}
            >
              <div
                style={{
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "1%",
                }}
              >
                <img
                  src={product.img_src}
                  onError={(e) => {
                    e.target.src = fallbackImage; 
                  }}
                  style={{
                    height: "80px",
                    width: "80px",
                    objectFit: "cover",
                    border: "1px solid lightgrey",
                    borderRadius: "10px",
                    marginRight: "5px",
                  }}
                />
              </div>
              <div style={{ width: "39%", paddingRight: "1%" }}>
                <div style={{ fontweight: "600", color: "#d1ae6e" }}>
                  {product.name}
                </div>
                <div>
                  {product.description
                    ? product.description.slice(0, 70) +
                      (product.description.length > 70 ? " ..." : "")
                    : "None"}{" "}
                </div>
              </div>
              <div
                style={{
                  width: "16%",
                  paddingRight: "1%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  {product.sku
                    ? product.sku.slice(0, 10) +
                      (product.sku.length > 10 ? " ..." : "")
                    : "None"}
                </div>
              </div>
              <div
                style={{ width: "16%", paddingRight: "1%", display: "flex" }}
              >
                {product.stock > 10 ? (
                  <div style={{ color: "#0d8c22", fontweight: "600" }}>
                    In stock
                  </div>
                ) : product.stock <= 10 && product.stock > 0 ? (
                  <div style={{ color: "#e62e2e", fontweight: "600" }}>
                    Low stock
                  </div>
                ) : product.stock === 0 ? (
                  <div style={{ color: "#e62e2e", fontweight: "600" }}>
                    No stock
                  </div>
                ) : (
                  <></>
                )}

                <div style={{ marginLeft: "5px" }}>({product.stock})</div>
              </div>
              <div style={{ width: "9%", paddingRight: "1%" }}>
                {product.categories}
              </div>

              <div style={{ width: "9%", paddingRight: "1%" }}>
                {product.tags}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Product;
