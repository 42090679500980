import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import Cookies from "js-cookie";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const Report = () => {
  ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const token = Cookies.get("token");
  const [allInvoice, setAllInvoice] = useState([]);
  const [allQuote, setAllQuote] = useState([]);
  const getInvoice = async () => {
    try {
      const response = await fetch(
        "https://www.sales.gclightinggallery.com/laravel/api/getInvoice",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchInvoice = async () => {
      const gottenInvoice = await getInvoice();
      if (gottenInvoice) {
        const updatedInvoices = gottenInvoice.invoice.map((invoice) => ({
          ...invoice,
          less_cv: parseFloat(invoice.less_cv, 10),
          discount: parseFloat(invoice.discount, 10),
          status: parseFloat(invoice.status, 10),
        }));
        setAllInvoice(updatedInvoices);
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchInvoice();
  }, []);

  const getQuote = async () => {
    try {
      const response = await fetch(
        "https://www.sales.gclightinggallery.com/laravel/api/getQuote",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchQuote = async () => {
      const gottenQuote = await getQuote();
      if (gottenQuote) {
        const updatedQuotes = gottenQuote.quotes.map((quote) => ({
          ...quote,
          less_cv: parseFloat(quote.less_cv, 10),
          discount: parseFloat(quote.discount, 10),
          status: parseFloat(quote.status, 10),
        }));
        setAllQuote(updatedQuotes);
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchQuote();
  }, []);

  // Function to filter data by date range
  const filterDataByDateRange = (data, dateRange) => {
    return data.filter((item) => {
      const itemDate = new Date(item.date); // Convert the item's date string to a Date object
      return itemDate >= dateRange.startDate && itemDate <= dateRange.endDate;
    });
  };

  const [dateRange, setDateRange] = useState({
    startDate: new Date("2023-01-01"),
    endDate: new Date("2024-12-31"),
    key: "selection",
  });

  const filteredQuotes = filterDataByDateRange(allQuote, dateRange);
  const filteredInvoices = filterDataByDateRange(allInvoice, dateRange);

  const [dateRangeIsOpen, setDateRangeIsOpen] = useState(false);
  const getReceiptValue = () => {
    let letReceipts = 0;

    for (const invoice of filteredInvoices) {
      letReceipts += parseFloat(invoice.record_payment);
    }
    return letReceipts.toFixed(2);
  };

  const getOutstandingValue = () => {
    let letOutstanding = 0;

    for (const invoice of filteredInvoices) {
      letOutstanding +=
        parseFloat(
          invoice.product.reduce(
            (acc, item) =>
              acc +
              (item.quantity ? parseFloat(item.quantity) : 0) *
                (item.UnitPrice ? parseFloat(item.UnitPrice) : 0),
            0
          ) -
            (invoice.discount ? parseFloat(invoice.discount) : 0) -
            (invoice.less_cv ? parseFloat(invoice.less_cv) : 0)
        ) *
          1.09 -
        (invoice.record_payment ? parseFloat(invoice.record_payment) : 0);
    }
    return parseFloat(letOutstanding.toFixed(2));
  };

  const getTotalRevenue = () => {
    let letOutstanding = 0;

    for (const invoice of filteredInvoices) {
      letOutstanding +=
        parseFloat(
          invoice.product.reduce(
            (acc, item) =>
              acc +
              (item.quantity ? parseFloat(item.quantity) : 0) *
                (item.UnitPrice ? parseFloat(item.UnitPrice) : 0),
            0
          ) -
            (invoice.discount ? parseFloat(invoice.discount) : 0) -
            (invoice.less_cv ? parseFloat(invoice.less_cv) : 0)
        ) * 1.09;
    }
    return parseFloat(letOutstanding.toFixed(2));
  };

  // Initialize objects to store data
  const salesData = {};
  const salesCount = {};

  // Process invoice data
  filteredInvoices.forEach((invoice) => {
    const sales = invoice.sales;
    const recordPayment = parseFloat(invoice.record_payment) || 0;

    // Add the record payment to the salesData object
    if (salesData[sales]) {
      salesData[sales] += recordPayment;
    } else {
      salesData[sales] = recordPayment;
    }

    // Count occurrences of each salesperson's name
    if (salesCount[sales]) {
      salesCount[sales]++;
    } else {
      salesCount[sales] = 1;
    }
  });

  // Extract unique salesperson names (labels)
  const uniqueSales = Object.keys(salesData);

  // Calculate the total record payment for each salesperson
  const totalRecordPayments = uniqueSales.map((sales) => salesData[sales]);

  const data = {
    labels: uniqueSales,
    datasets: [
      {
        label: "Received",
        data: totalRecordPayments,
        backgroundColor: [
          "rgb(209, 174, 110)",
          "rgb(40, 40, 40)",
          "rgb(209, 174, 110)",
          "rgb(40, 40, 40)",
          "rgb(209, 174, 110)",
          "rgb(40, 40, 40)",
          "rgb(209, 174, 110)",
          "rgb(40, 40, 40)",
          "rgb(209, 174, 110)",
          "rgb(40, 40, 40)",
          "rgb(209, 174, 110)",
          "rgb(40, 40, 40)",
          "rgb(209, 174, 110)",
          "rgb(40, 40, 40)",
          "rgb(209, 174, 110)",
          "rgb(40, 40, 40)",
          "rgb(209, 174, 110)",
          "rgb(40, 40, 40)",
          "rgb(209, 174, 110)",
          "rgb(40, 40, 40)",
          "rgb(209, 174, 110)",
          "rgb(40, 40, 40)",
          "rgb(209, 174, 110)",
          "rgb(40, 40, 40)",
        ],
        borderRadius: 10,
      },
    ],
  };

  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      responsive: true,
    },
    type: "bar",
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
  };

  const data2 = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Expected Revenue",
        data: [52, 91, 33, 85, 52, 23, 73, 57, 35, 82],
        backgroundColor: "rgb(209, 174, 110)",
        borderColor: "rgb(209, 174, 110)",
        borderWidth: 1,
        borderRadius: 8,
      },
      {
        label: "Receipt",
        data: [35, 82, 83, 25, 52, 91, 33, 85, 52, 23],
        backgroundColor: "rgb(40, 40, 40)",
        borderColor: "rgb(40, 40, 40)",
        borderWidth: 1,
        borderRadius: 8,
      },
    ],
  };

  const handleSelect = (ranges) => {
    console.log(ranges);
  };
  // Create an object to store monthly totals
  const monthlyTotals = {
    Jan: 0,
    Feb: 0,
    Mar: 0,
    Apr: 0,
    May: 0,
    Jun: 0,
    Jul: 0,
    Aug: 0,
    Sep: 0,
    Oct: 0,
    Nov: 0,
    Dec: 0,
  };

  // Loop through filtered invoice data and calculate monthly totals
  const monthAbbreviations = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  // Loop through filtered invoice data and calculate monthly totals
  filteredInvoices.forEach((invoice) => {
    const month = monthAbbreviations[invoice.date.split("-")[1]];
    const total = invoice.product.reduce(
      (acc, item) =>
        acc +
        (item.quantity ? parseFloat(item.quantity) : 0) *
          (item.UnitPrice ? parseFloat(item.UnitPrice) : 0),
      0
    );
    console.log(monthlyTotals[month]);
    monthlyTotals[month] += parseFloat(
      (
        (total - parseFloat(invoice.discount) - parseFloat(invoice.less_cv)) *
        1.09
      ).toFixed(2)
    );
  });

  // Fill the data array in the datasets
  data2.datasets[0].data = Object.values(monthlyTotals);

  const monthlyPayments = {
    Jan: 0,
    Feb: 0,
    Mar: 0,
    Apr: 0,
    May: 0,
    Jun: 0,
    Jul: 0,
    Aug: 0,
    Sep: 0,
    Oct: 0,
    Nov: 0,
    Dec: 0,
  };

  // Loop through filtered invoice data and accumulate record_payment values for each month
  filteredInvoices.forEach((invoice) => {
    const month = monthAbbreviations[invoice.date.split("-")[1]];
    const recordPayment = parseFloat(invoice.record_payment);
    monthlyPayments[month] += recordPayment;
  });

  // Create an array of cumulative payments
  const cumulativePayments = Object.values(monthlyPayments);

  // Assign the cumulative payments to the second dataset in data2
  data2.datasets[1].data = cumulativePayments;
  console.log(data2);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    // Add event listener to update the screenWidth state when the window is resized
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const screenWidthForChart = screenWidth / 1.4;
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          padding: "0px 20px",
          height: "70px",
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid lightgrey",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
        }}
      >
        <div
          style={{
            margin: "0px",
            fontSize: "32PX",
            fontWeight: "700",
            marginRight: "10px",
            cursor: "pointer",
          }}
        >
          Report
        </div>
      </div>
      <div
        style={{
          height: "calc(100vh - 70px)",
          width: "100%",
          background: "rgb(245, 242, 240)",
        }}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
            padding: "20px 40px 20px 20px",
          }}
        >
          <div
            style={{
              background: "white",
              padding: "8px 20px",
              borderRadius: "12px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
              cursor: "pointer",
            }}
            onClick={() => setDateRangeIsOpen(!dateRangeIsOpen)}
          >
            Filter by date
          </div>
          {dateRangeIsOpen ? (
            <div
              style={{
                position: "absolute",
                marginTop: "40px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
              }}
            >
              <DateRangePicker
                ranges={[dateRange]}
                onChange={(range) => setDateRange(range.selection)}
                rangeColors={"#d1ae6e"}
                color={"#d1ae6e"}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          style={{ width: "calc(100% - 40px)", padding: "0px 20px 0px 20px" }}
        >
          <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
            <div
              style={{
                position: "relative",
                width:
                  windowHeight > screenWidth
                    ? "calc(50% - 60px)"
                    : "calc(25% - 60px)",
                background: "#282828",
                marginRight: "20px",
                height: windowHeight > screenWidth ? "120px" : "80px",
                borderRadius: "15px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div
                className="blink"
                style={{
                  position: "absolute",
                  top: 15,
                  right: 15,
                  background: "#ebd1a2",
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  boxShadow: "0px 0px 10px #eba1a2",
                }}
              ></div>
              <div
                style={{
                  width: "90%",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#ebd1a2",
                }}
              >
                Forecast Total Revenue
              </div>
              <div
                style={{
                  width: "90%",
                  fontSize: "30px",
                  fontWeight: "700",
                  color: "#d1ae6e",
                }}
              >
                SGD {getTotalRevenue()}
              </div>
            </div>
            <div
              style={{
                width:
                  windowHeight > screenWidth
                    ? "calc(50% - 60px)"
                    : "calc(25% - 60px)",
                background: "white",
                marginRight: "20px",
                height: windowHeight > screenWidth ? "120px" : "80px",
                borderRadius: "15px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#282828",
                }}
              >
                Outstanding
              </div>
              <div
                style={{
                  width: "90%",
                  fontSize: "30px",
                  fontWeight: "700",
                  color: "#282828",
                }}
              >
                SGD {getOutstandingValue()}
              </div>
            </div>
            <div
              style={{
                width:
                  windowHeight > screenWidth
                    ? "calc(50% - 60px)"
                    : "calc(25% - 60px)",
                background: "white",
                marginRight: "20px",
                height: windowHeight > screenWidth ? "120px" : "80px",
                borderRadius: "15px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#282828",
                }}
              >
                Receipts
              </div>
              <div
                style={{
                  width: "90%",
                  fontSize: "30px",
                  fontWeight: "700",
                  color: "#282828",
                }}
              >
                SGD {getReceiptValue()}
              </div>
            </div>
            <div
              style={{
                width:
                  windowHeight > screenWidth
                    ? "calc(50% - 60px)"
                    : "calc(25% - 60px)",
                background: "white",
                marginRight: "20px",
                height: windowHeight > screenWidth ? "120px" : "80px",
                borderRadius: "15px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#282828",
                }}
              >
                Expense
              </div>
              <div
                style={{
                  width: "90%",
                  fontSize: "30px",
                  fontWeight: "700",
                  color: "#282828",
                }}
              >
                SGD 0.00
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "calc(100% - 40px)", padding: "20px" }}>
          <div style={{ width: "100%", display: "flex" }}>
            <div
              style={{
                width: "40%",
                background: "white",
                marginRight: "20px",
                height: "300px",
                borderRadius: "15px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  padding: "20px 0px 0px 0px",
                  width: "90%",
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#282828",
                }}
              >
                Sales Team KPI
              </div>
              <div style={{ height: "260px", padding: "0px 20px 20px 20px" }}>
                <Pie data={data} options={options2} />
              </div>
            </div>
            <div
              style={{
                width: "60%",
                background: "white",
                marginRight: "20px",
                height: "300px",
                borderRadius: "15px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  padding: "20px 0px 20px 0px",
                  width: "90%",
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#282828",
                }}
              >
                Sales and Receipts
              </div>
              <div style={{ height: "260px", padding: "0px 20px 20px 20px" }}>
                <Bar
                  options={options2}
                  data={data2}
                  width={screenWidthForChart}
                  height={400}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
