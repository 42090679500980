import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./App.css";
import axios from "axios";

const UserAccess = () => {
  const [user, setUser] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [showPasswordStates, setShowPasswordStates] = useState(
    user.map(() => false)
  );
  const [isNewAccount, setIsNewAccount] = useState(true);

  const [newAccountData, setNewAccountData] = useState({
    name: "",
    email: "",
    username: "",
    password: "",
    role: "Sales",
  });
  const [resetPasswordData, setResetPasswordData] = useState({
    email: "",
    password: "",
    password_confirmation: "",
  });

  const handleNewAccountInput = (e) => {
    const { name, value } = e.target;
    setNewAccountData({ ...newAccountData, [name]: value });
  };

  const handleResetPasswordInput = (e) => {
    const { name, value } = e.target;
    setResetPasswordData({ ...resetPasswordData, [name]: value });
  };

  const [signUpError, setSignUpError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleAddNewUsers = async (e) => {
    setSignUpError(false);
    e.preventDefault();

    const now = new Date();

    // Add the new product to the allProduct array
    console.log(newAccountData);

    try {
      const response = await fetch("https://www.sales.gclightinggallery.com/laravel/api/signup", {
        body: JSON.stringify({
          ...newAccountData,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
      });
      if (!response.ok) {
        setSignUpError(true);
        throw new Error("Network response was not ok");
      }

      // Clear the form data after adding the product
      if (isNewAccount) {
        setUser([
          ...user,
          {
            ...newAccountData,
            created_at: now.toISOString(), // Format: "2023-08-15T04:25:58.000Z"
            updated_at: now.toISOString(),
          },
        ]);
      } else {
        const accountIndex = user.findIndex(
          (users) => users.id === newAccountData.id
        );
        const updateUser = [...user];
        updateUser[accountIndex] = newAccountData;
        setUser(updateUser);
      }
      setNewAccountData({
        name: "",
        email: "",
        username: "",
        password: "",
        created_at: "",
        updated_at: "",
        role: "Sales",
      });
      console.log(isNewAccount);
      setModalIsOpen(false);
    } catch (error) {
      setSignUpError(true);
      console.error("Error signing up:", error);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    console.log(resetPasswordData);
    try {
      const response = await fetch("https://www.sales.gclightinggallery.com/laravel/api/resetpassword", {
        body: JSON.stringify(resetPasswordData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
      });
      if (!response.ok) {
        setPasswordError(true);
        throw new Error("Network response was not ok");
      }

      setResetPasswordData({
        email: "",
        password: "",
        password_confirmation: "",
      });
      setResetPasswordOpen(false);
    } catch (error) {
      console.error("Error signing up:", error);
    }
  };

  const token = Cookies.get("token");
  const getUsers = async () => {
    try {
      const response = await fetch("https://www.sales.gclightinggallery.com/laravel/api/getusers", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };
  useEffect(() => {
    const fetchUsers = async () => {
      const webSections = await getUsers();
      if (webSections) {
        console.log(webSections.users);
        setUser(webSections.users);
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchUsers();
  }, []);

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          setIsNewAccount(true);
          setNewAccountData({
            name: "",
            email: "",
            username: "",
            password: "",
            role: "Sales",
          });
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "600px",
            height: "700px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            boxShadow: "none",
            borderRadius: "10px",
            border: "none",
            overflowX: "hidden",
            overflowY: "hidden",
            padding: "none",
          },
        }}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <div
            style={{
              display: "flex",
              width: "calc(100% - 40px)",
              justifyContent: "flex-end",
              padding: "20px",
            }}
          >
            <div
              className="close_icon"
              style={{
                opacity: "1",
                transition: "all 0.3s ease-in-out",
                border: "1px solid lightgrey",
                borderRadius: "50%",
                height: "40px",
                width: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalIsOpen(false);
                setIsNewAccount(true);
                setNewAccountData({
                  name: "",
                  email: "",
                  username: "",
                  password: "",
                  role: "Sales",
                });
              }}
            >
              <CloseIcon />
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              width: "calc(100% - 40px)",
              padding: "0px 20px",
              height: "75%",
            }}
          >
            <form onSubmit={handleAddNewUsers} style={{ width: "100%" }}>
              <AccountCircleIcon
                style={{ color: "lightgrey", fontSize: "200px" }}
              />
              <div style={{ width: "100%" }}>
                <input
                  type="text"
                  name="name"
                  value={newAccountData.name}
                  onChange={handleNewAccountInput}
                  placeholder="Input full name"
                  style={{
                    textAlign: "center",
                    padding: "8px 20px",
                    marginTop: "15px",
                    width: "300px",
                    borderRadius: "12px",
                    outline: "none",
                    border: "1px solid lightgrey",
                  }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <input
                  type="text"
                  name="email"
                  value={newAccountData.email}
                  onChange={handleNewAccountInput}
                  placeholder="Input email"
                  style={{
                    textAlign: "center",
                    padding: "8px 20px",
                    marginTop: "15px",
                    width: "300px",
                    borderRadius: "12px",
                    outline: "none",
                    border: "1px solid lightgrey",
                  }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <input
                  type="text"
                  name="username"
                  value={newAccountData.username}
                  onChange={handleNewAccountInput}
                  placeholder="Username"
                  style={{
                    textAlign: "center",
                    padding: "8px 20px",
                    marginTop: "15px",
                    width: "300px",
                    borderRadius: "12px",
                    outline: "none",
                    border: "1px solid lightgrey",
                  }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <select
                  type="text"
                  name="role"
                  value={newAccountData.role}
                  onChange={handleNewAccountInput}
                  placeholder="Password"
                  style={{
                    textAlign: "center",
                    padding: "8px 20px",
                    marginTop: "15px",
                    width: "340px",
                    borderRadius: "12px",
                    outline: "none",
                    border: "1px solid lightgrey",
                  }}
                >
                  <option value="Sales">Sales</option>
                  <option value="Admin">Admin</option>
                  <option value="Finance">Finance</option>
                </select>
              </div>
              {isNewAccount ? (
                <>
                  <div style={{ width: "100%" }}>
                    <input
                      type="password"
                      name="password"
                      value={newAccountData.password}
                      onChange={handleNewAccountInput}
                      placeholder="Password"
                      style={{
                        textAlign: "center",
                        padding: "8px 20px",
                        marginTop: "15px",
                        width: "300px",
                        borderRadius: "12px",
                        outline: "none",
                        border: "1px solid lightgrey",
                      }}
                    />
                  </div>
                  <div style={{ color: "red", textAlign: "center" }}>
                    {newAccountData.password
                      ? newAccountData.password.length < 8
                        ? "Password needs to be at least 8 characters"
                        : null
                      : null}
                  </div>
                </>
              ) : (
                <></>
              )}
              <div style={{ color: "red", textAlign: "center" }}>
                {signUpError ? "invalid email or password" : null}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {isNewAccount ? (
                  <></>
                ) : (
                  <button
                    onClick={async (e) => {
                      e.preventDefault();
                      console.log(newAccountData.id);
                      const token = Cookies.get("token");
                      const userIndex = user.findIndex(
                        (users) => users.id === newAccountData.id
                      );
                      const removingUser = [...user];
                      removingUser.splice(userIndex, 1);
                      setUser(removingUser);
                      try {
                        const response = await axios.delete(
                          `https://www.sales.gclightinggallery.com/laravel/api/deleteuser/${newAccountData.id}`,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        );

                        setModalIsOpen(false);
                        setIsNewAccount(true);
                        setNewAccountData({
                          name: "",
                          email: "",
                          username: "",
                          password: "",
                          role: "Sales",
                        });
                      } catch (error) {
                        console.log(error);
                        setModalIsOpen(false);
                        setIsNewAccount(true);
                        setNewAccountData({
                          name: "",
                          email: "",
                          username: "",
                          password: "",
                          role: "Sales",
                        });
                      }
                    }}
                    style={{
                      border: "1px solid #d1ae6e",
                      color: "#d1ae6e",
                      padding: "8px 25px",
                      background: "white",
                      cursor: "pointer",
                      borderRadius: "10px",
                      outline: "none",
                      fontSize: "18px",
                      marginTop: "40px",
                      marginRight: "10px",
                    }}
                  >
                    Delete
                  </button>
                )}
                <button
                  type="submit"
                  style={{
                    background: "#d1ae6e",
                    padding: "8px 25px",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: "10px",
                    border: "none",
                    outline: "none",
                    fontSize: "18px",
                    marginTop: "40px",
                  }}
                >
                  {isNewAccount ? "Submit" : "Update"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={resetPasswordOpen}
        onRequestClose={() => {
          setResetPasswordOpen(false);
          setResetPasswordData({
            email: "",
            password: "",
            password_confirmation: "",
          });
          setPasswordError(false);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            height: "400px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            boxShadow: "none",
            borderRadius: "10px",
            border: "none",
            overflowX: "hidden",
            overflowY: "hidden",
            padding: "none",
          },
        }}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <div
            style={{
              display: "flex",
              width: "calc(100% - 40px)",
              justifyContent: "flex-end",
              padding: "20px",
            }}
          >
            <div
              className="close_icon"
              style={{
                opacity: "1",
                transition: "all 0.3s ease-in-out",
                border: "1px solid lightgrey",
                borderRadius: "50%",
                height: "40px",
                width: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setResetPasswordOpen(false);
                setResetPasswordData({
                  email: "",
                  password: "",
                  password_confirmation: "",
                });
                setPasswordError(false);
              }}
            >
              <CloseIcon />
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              width: "calc(100% - 40px)",
              padding: "0px 20px",
              height: "75%",
            }}
          >
            <form onSubmit={handleResetPassword} style={{ width: "100%" }}>
              <div>{resetPasswordData.email}</div>
              <div style={{ width: "100%" }}>
                <input
                  type="password"
                  name="password"
                  value={resetPasswordData.password}
                  onChange={handleResetPasswordInput}
                  placeholder="New password"
                  style={{
                    textAlign: "center",
                    padding: "8px 20px",
                    marginTop: "15px",
                    width: "50%",
                    borderRadius: "12px",
                    outline: "none",
                    border: "1px solid lightgrey",
                  }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <input
                  type="password"
                  name="password_confirmation"
                  value={resetPasswordData.password_confirmation}
                  onChange={handleResetPasswordInput}
                  placeholder="Retype password"
                  style={{
                    textAlign: "center",
                    padding: "8px 20px",
                    marginTop: "15px",
                    width: "50%",
                    borderRadius: "12px",
                    outline: "none",
                    border: "1px solid lightgrey",
                  }}
                />
              </div>
              <div style={{ color: "red", textAlign: "center" }}>
                {passwordError
                  ? "The password field must be at least 8 characters"
                  : null}
              </div>
              {resetPasswordData.password ? (
                resetPasswordData.password !==
                resetPasswordData.password_confirmation ? (
                  <div style={{ color: "red", textAlign: "center" }}>
                    Password does not match
                  </div>
                ) : null
              ) : null}
              <button
                type="submit"
                className="reset_password_submit"
                style={{
                  background: "#d1ae6e",
                  padding: "8px 25px",
                  color: "white",
                  cursor: "pointer",
                  borderRadius: "10px",
                  border: "none",
                  outline: "none",
                  fontSize: "18px",
                  marginTop: "40px",
                }}
                disabled={
                  resetPasswordData.password !==
                  resetPasswordData.password_confirmation
                }
              >
                Reset password
              </button>
            </form>
          </div>
        </div>
      </Modal>
      <div
        style={{
          padding: "0px 20px",
          height: "7vh",
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid lightgrey",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
        }}
      >
        <div
          style={{
            margin: "0px",
            fontSize: "32PX",
            fontWeight: "700",
            marginRight: "10px",
            cursor: "pointer",
          }}
        >
          User Access Control
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              background: "#d1ae6e",
              borderRadius: "7px",
              display: "flex",
              alignItems: "center",
              padding: "6px 15px 6px 10px",
              cursor: "pointer",
              marginRight: "5px",
              marginTop: "5px",
            }}
            onClick={() => setModalIsOpen(true)}
          >
            <AddIcon style={{ color: "white", fontSize: "18px" }} />
            <div
              style={{
                color: "white",
                fontSize: "13px",
                marginBottom: "2.5px",
              }}
            >
              Create User
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: "#f5f2f0", width: "100%", height: "93vh" }}>
        <div style={{ padding: "20px", height: "calc(100% - 40px)" }}>
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "white",
              borderRadius: "20px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "15px 20px",
                fontSize: "15px",
                fontWeight: "700",
                borderBottom: "1px solid lightgrey",
              }}
            >
              <div style={{ width: "25%" }}>Name</div>
              <div style={{ width: "25%" }}>Email</div>
              <div style={{ width: "15%" }}>Role</div>
              <div style={{ width: "10%", marginLeft: "15px" }}>Created</div>
              <div style={{ width: "10%" }}>Last Activity</div>
            </div>
            {user.map((user, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  padding: "14px 20px",
                  fontSize: "13px",
                  fontWeight: "500",
                  borderBottom: "1px solid rgba(0,0,0,0.05)",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => {
                    setIsNewAccount(false);
                    setModalIsOpen(true);
                    setNewAccountData(user);
                  }}
                  style={{ width: "25%", cursor: "pointer" }}
                >
                  {user.name}
                </div>
                <div
                  onClick={() => {
                    setIsNewAccount(false);
                    setModalIsOpen(true);
                    setNewAccountData(user);
                  }}
                  style={{ width: "25%", cursor: "pointer" }}
                >
                  {user.email}
                </div>
                <div
                  onClick={() => {
                    setIsNewAccount(false);
                    setModalIsOpen(true);
                    setNewAccountData(user);
                  }}
                  style={{ width: "15%", cursor: "pointer" }}
                >
                  {user.role}
                </div>
                <div
                  onClick={() => {
                    setIsNewAccount(false);
                    setModalIsOpen(true);
                    setNewAccountData(user);
                  }}
                  style={{
                    width: "10%",
                    marginLeft: "15px",
                    cursor: "pointer",
                  }}
                >
                  {formatDate(user.created_at)}
                </div>
                <div
                  onClick={() => {
                    setIsNewAccount(false);
                    setModalIsOpen(true);
                    setNewAccountData(user);
                  }}
                  style={{ width: "10%", cursor: "pointer" }}
                >
                  {formatDate(user.updated_at)}
                </div>
                <div
                  className="reset_password_button"
                  style={{
                    border: "1px solid lightgrey",
                    borderRadius: "10px",
                    padding: "5px 12px",
                    color: "#b5b5b5",
                  }}
                  onClick={() => {
                    setResetPasswordData({
                      ...resetPasswordData,
                      email: user.email,
                    });
                    setResetPasswordOpen(true);
                  }}
                >
                  Reset password
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAccess;
