import React from "react";
import logo from "./img/gc_logo.png";
import bg from "./img/login_bg.jpg";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://www.sales.gclightinggallery.com/laravel/api/login",
        formData
      );
      console.log(response.data);

      const token = response.data.token;
      Cookies.set("token", token, { expires: 1 }); // store token in cookies

      const redirectUrl = response.data.redirect_url;
      console.log(redirectUrl);

      if (redirectUrl === "http://localhost:3000/dashboard") {
        navigate("/dashboard"); // navigate to dashboard page
      }

      // Handle success or redirect to another page
    } catch (error) {
      console.error(error.response.data); // Error response from the Laravel API
      // Handle error or show error messages
      const errors = [];

      // Check for specific error messages related to the password field
      if (error.response.data.message) {
        errors.push(error.response.data.message);
      } else if (
        error.response.data.errors.password &&
        error.response.data.errors.email
      ) {
        errors.push("Email and password field is empty");
      } else if (error.response.data.errors.password) {
        errors.push(error.response.data.errors.password);
      } else if (error.response.data.errors.email) {
        errors.push(error.response.data.errors.email);
      }
      // Set the array of error messages to the state
      setError(errors);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        overflowX: "hidden",
        overflowY: "hidden",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 50,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-betweem",
        }}
      >
        <div
          style={{
            width: "50%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ height: "90px", paddingTop: "15px" }}>
            <img src={logo} style={{ height: "90px", objectFit: "contain" }} />
          </div>
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: "200px",
              marginTop: "-100px",
            }}
          >
            <div
              style={{
                color: "rgba(256,256,256,0.5)",
                fontSize: "20px",
                marginBottom: "0px",
              }}
            >
              WELCOME GC TEAM
            </div>
            <div
              style={{
                color: "white",
                fontSize: "60px",
                display: "flex",
                fontWeight: "600",
                marginBottom: "10px",
                whiteSpace: "nowrap", // Prevent line breaks
              }}
            >
              <div>Login to your account</div>
              <span style={{ color: "#d1ae6e", marginLeft: "5px" }}>.</span>
            </div>
            <div
              style={{
                color: "rgba(256,256,256,0.5)",
                fontSize: "16px",
                display: "flex",
                marginBottom: "30px",
              }}
            >
              <div style={{ marginRight: "10px" }}>
                Forgotten your password?
              </div>
              <div
                className="hover_underline"
                style={{
                  color: "#d1ae6e",
                  cursor: "pointer",
                }}
              >
                Reset password
              </div>
            </div>
            <input
              className="login_input"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              style={{
                padding: "15px 20px",
                borderRadius: "10px",
                marginBottom: "15px",
                width: "500px",
              }}
            />
            <input
              className="login_input"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
              style={{
                padding: "15px 20px",
                borderRadius: "10px",
                marginBottom: "10px",
                width: "500px",
              }}
            />
            <div style={{ color: "red", marginBottom: "30px" }}>{error}</div>
            <button
              type="submit"
              className="gc_button"
              style={{
                width: "200px",
                padding: "15px 20px",
                fontSize: "20px",
                color: "white",
                borderRadius: "50px",
                background: "#d1ae6e",
                fontWeight: "600",
              }}
            >
              Login
            </button>
          </form>
        </div>
        <div style={{ width: "50%", height: "100%" }}></div>
      </div>
      <img src={bg} style={{ width: "100%", height: "100vh" }} />
    </div>
  );
};
export default Login;
