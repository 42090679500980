import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import AddIcon from "@mui/icons-material/Add";
import logo from "./img/gc_logo.png";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PaidIcon from "@mui/icons-material/Paid";
import ReactPDF from "@react-pdf/renderer";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import Cookies from "js-cookie";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Modal from "react-modal";
import { parse } from "uuid";
import { useNavigate } from "react-router-dom";

const Receipt = ({ convertToInvoice, onLoadClearActive, userId }) => {
  const navigate = useNavigate();
  const [invoiceDataForCondition, setinvoiceDataForCondition] = useState();
  const [allInvoice, setAllInvoice] = useState([]);
  const [showClientList, setShowClientList] = useState(false);
  const [showProductList, setShowProductList] = useState(false);
  const [userData, setUserData] = useState();
  const [voucherIsOpen, setVoucherIsOpen] = useState(false);
  const [discountIsOpen, setDiscountIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(""); // Initialize with an empty string or default user if needed

  function getCurrentYear() {
    const now = new Date();
    return now.getFullYear();
  }

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        "https://www.sales.gclightinggallery.com/laravel/api/getuserdata",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setUserData(data.user_data);
      console.log(data.user_data);
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };
  useEffect(() => {
    if (!token) {
      Cookies.remove("token");
      navigate("/"); // programmatically navigate to /home
    }
    fetchUserData();
  }, []);

  const calculateTotalValueGiven = (invoiceData) => {
    let totalValue = 0;

    for (const product of invoiceData.product) {
      const quantity = product.quantity;
      const unitPrice = parseFloat(product.UnitPrice); // Assuming the UnitPrice is a string, convert it to a number if needed
      const productValue = quantity * unitPrice;
      totalValue +=
        isNaN(productValue) || productValue === 0 ? 0 : productValue;
    }

    return totalValue;
  };
  const [allProduct, setAllProduct] = useState([]);
  const getNextInvoiceNumber = () => {
    // If there are no invoices, start with 0001
    if (allInvoice.length === 0) {
      return "0001";
    }

    // Extract existing invoice numbers and convert them to integers
    const existingInvoiceNumbers = allInvoice.map((invoice) =>
      parseInt(invoice.invoice_number, 10)
    );

    // Find the maximum invoice number
    const maxInvoiceNumber = Math.max(...existingInvoiceNumbers);

    // Calculate the next invoice number
    const nextInvoiceNumber = maxInvoiceNumber + 1;

    // Convert the next invoice number to a 4-digit style
    const nextInvoiceNumberFormatted = nextInvoiceNumber
      .toString()
      .padStart(4, "0");

    return nextInvoiceNumberFormatted;
  };

  const today = new Date(); // Get the current date

  const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
  const [searchQueryClient, setSearchQueryClient] = useState("");
  const [searchAreaClient, setSearchAreaClient] = useState("");
  const [searchQueryProduct, setSearchQueryProduct] = useState("");
  const [newInvoice, setNewInvoice] = useState({
    invoice_number: "",
    client_name: "",
    date: formattedDate,
    sales: userData ? userData.name : "",
    designer: "",
    phone_number: "",
    address: "",
    status: "",
    discount: 0,
    less_cv: 0,
    record_payment: 0,
    voucher: "",
    product: [],
  });

  const [invoiceData, setInvoiceData] = useState();
  const [creaatingInvoice, setCreatingInvoice] = useState(false);
  const styles = StyleSheet.create({
    row: {
      flexDirection: "row", // This works like display: "flex"
      justifyContent: "space-between",
      marginBottom: 10,
    },
    logoContainer: {
      flexDirection: "row", // This is to make sure the logo and text are in a row
      justifyContent: "space-between",
      alignItems: "center", // Optional: Align items vertically within the
      marginBottom: "20px",
    },
    logo: {
      width: "200px",
      marginLeft: 10,
    },
    textSmall: {
      fontSize: 10,
    },
    textHeavy: {
      fontSize: 15,
      fontWeight: "bold", // Use "normal" or "bold" for font weight
    },
  });
  const getInvoice = async () => {
    try {
      const response = await fetch(
        "https://www.sales.gclightinggallery.com/laravel/api/getInvoice",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchInvoice = async () => {
      const gottenInvoice = await getInvoice();
      if (gottenInvoice) {
        const updatedInvoices = gottenInvoice.invoice.map((invoice) => ({
          ...invoice,
          less_cv: parseFloat(invoice.less_cv, 10),
          discount: parseFloat(invoice.discount, 10),
          status: parseFloat(invoice.status, 10),
        }));
        setAllInvoice(updatedInvoices);
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchInvoice();
  }, []);

  useEffect(() => {
    console.log(invoiceData);
    if (convertToInvoice) {
      console.log("creating invoice", convertToInvoice);
      setCreatingInvoice(true);
      setNewInvoice({
        invoice_number: convertToInvoice.invoice_number,
        client_name: convertToInvoice.client_name,
        date: formattedDate,
        sales: convertToInvoice.sales,
        designer: convertToInvoice.designer,
        phone_number: convertToInvoice.phone_number,
        address: convertToInvoice.address,
        status: convertToInvoice.status,
        discount: convertToInvoice.discount,
        less_cv: convertToInvoice.less_cv,
        voucher: convertToInvoice.voucher,
        product: convertToInvoice.product,
        record_payment: 0,
      });
    }
    onLoadClearActive();
  }, []);

  const updateInvoiceStatus = async (updatedInvoice) => {
    try {
      const response = await fetch(
        "https://www.sales.gclightinggallery.com/laravel/api/createAndUpdateInvoice",
        {
          body: JSON.stringify(updatedInvoice),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Update the state with the updated invoices
      const updatedInvoices = allInvoice.map((invoice) =>
        invoice.invoice_number === updatedInvoice.invoice_number
          ? updatedInvoice
          : invoice
      );
      setAllInvoice(updatedInvoices);
    } catch (error) {
      console.error("Error updating invoice status:", error);
    }
  };
  const getDateForViewing = (date) => {
    const dateString = date;
    const dateParts = dateString.split("-");
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Month is 0-based
    const day = parseInt(dateParts[2]);

    return new Date(year, month, day);
  };

  const [clientData, setClientData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchClientInputChange = (e) => {
    setSearchQueryClient(e.target.value);
  };

  const handleSearchAreaInputChange = (e) => {
    setSearchAreaClient(e.target.value);
  };

  const handleSearchProductInputChange = (e) => {
    setSearchQueryProduct(e.target.value);
  };

  const filteredInvoice = allInvoice.filter((invoice) => {
    const { client_name, invoice_number } = invoice;
    const lowerCaseSearchQuery = searchQuery.toLowerCase();

    return (
      (client_name &&
        client_name.toLowerCase().includes(lowerCaseSearchQuery)) ||
      (invoice_number &&
        invoice_number.toLowerCase().includes(lowerCaseSearchQuery))
    );
  });

  const filteredProduct = allProduct.filter((product) => {
    const { name, sku } = product;
    const lowerCaseSearchQuery = searchQueryProduct.toLowerCase();

    return (
      (name && name.toLowerCase().includes(lowerCaseSearchQuery)) ||
      (sku && sku.toLowerCase().includes(lowerCaseSearchQuery))
    );
  });

  const filteredClient = clientData.filter((client) => {
    const { name, email } = client;
    const lowerCaseSearchQuery = searchQueryClient.toLowerCase();

    return (
      (name && name.toLowerCase().includes(lowerCaseSearchQuery)) ||
      (email && email.toLowerCase().includes(lowerCaseSearchQuery))
    );
  });

  const token = Cookies.get("token");
  const getCustomer = async () => {
    try {
      const response = await fetch(
        "https://www.sales.gclightinggallery.com/laravel/api/getcustomer",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      const gottonCustomer = await getCustomer();
      if (gottonCustomer) {
        console.log(gottonCustomer.customers);
        setClientData(gottonCustomer.customers);
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchCustomers();
  }, []);

  const invoiceFileName = `Receipt ${invoiceData ? invoiceData.invoice_number : "0"
    }.pdf`;

  const handleClickOutsideShowClient = (event) => {
    const showClientContainer = document.getElementById(
      "show_client_container"
    );
    const clientTrigger = document.getElementById("show_client_trigger");

    if (
      showClientContainer &&
      !showClientContainer.contains(event.target) &&
      !clientTrigger.contains(event.target)
    ) {
      setShowClientList(false);
    }
  };

  useEffect(() => {
    if (showClientList) {
      window.addEventListener("click", handleClickOutsideShowClient);
    } else {
      window.removeEventListener("click", handleClickOutsideShowClient);
    }

    return () => {
      window.removeEventListener("click", handleClickOutsideShowClient);
    };
  }, [showClientList]);

  const handleClickOutsideShowProduct = (event) => {
    const showProductContainer = document.getElementById(
      "show_product_container"
    );
    const productTrigger = document.getElementById("show_product_trigger");

    if (
      showProductContainer &&
      !showProductContainer.contains(event.target) &&
      !productTrigger.contains(event.target)
    ) {
      setShowProductList(false);
    }
  };

  useEffect(() => {
    if (showProductList) {
      window.addEventListener("click", handleClickOutsideShowProduct);
    } else {
      window.removeEventListener("click", handleClickOutsideShowProduct);
    }

    return () => {
      window.removeEventListener("click", handleClickOutsideShowProduct);
    };
  }, [showProductList]);

  const calculateTotalValue = () => {
    let totalValue = 0;

    for (const product of newInvoice.product) {
      const quantity = product.quantity;
      const unitPrice = parseFloat(product.UnitPrice); // Assuming the UnitPrice is a string, convert it to a number if needed
      const productValue = quantity * unitPrice;
      totalValue +=
        isNaN(productValue) || productValue === 0 ? 0 : productValue;
    }

    return totalValue;
  };

  const getProducts = async () => {
    try {
      const response = await fetch(
        "https://www.sales.gclightinggallery.com/laravel/api/getproducts",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const gottenProducts = await getProducts();
      if (gottenProducts) {
        console.log(gottenProducts.product);
        setAllProduct(gottenProducts.product);
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchProducts();
  }, []);
  const [allArea, setAllArea] = useState([]);

  useEffect(() => {
    // Extract all unique tags from allProduct and store them in allArea
    const uniqueTags = allProduct.reduce((tags, product) => {
      const productTags = product.tags || ""; // Ensure productTags is not null
      productTags.split(" | ").forEach((tag) => {
        if (tag.trim() !== "" && !tags.includes(tag)) {
          tags.push(tag);
        }
      });
      return tags;
    }, []);

    setAllArea(uniqueTags);
    console.log(uniqueTags);
  }, [allProduct]);

  const filteredArea = allArea.filter((area) => {
    const lowerCaseSearchQuery = searchAreaClient.toLowerCase();
    console.log(searchAreaClient);
    return area && area.toLowerCase().includes(lowerCaseSearchQuery);
  });

  const [areaIsOpen, setAreaIsOpen] = useState(
    Array(newInvoice.product.length).fill(false)
  );

  const toggleDropdown = (index) => {
    const updatedIsOpen = [...areaIsOpen];
    updatedIsOpen[index] = !updatedIsOpen[index];
    setAreaIsOpen(updatedIsOpen);
  };

  const handleClickOutsideShowArea = (event, index) => {
    const showAreaContainer = document.getElementById(
      `show_area_container_${index}`
    );
    const areaTrigger = document.getElementById(`show_area_trigger_${index}`);

    if (
      showAreaContainer &&
      !showAreaContainer.contains(event.target) &&
      !areaTrigger.contains(event.target)
    ) {
      setAreaIsOpen((prev) =>
        prev.map((value, i) => (i === index ? false : value))
      );
    }
  };

  useEffect(() => {
    const clickListener = (event) => {
      areaIsOpen.forEach((isOpen, index) => {
        if (isOpen) {
          handleClickOutsideShowArea(event, index);
        }
      });
    };

    if (areaIsOpen.some((isOpen) => isOpen)) {
      window.addEventListener("click", clickListener);
    } else {
      window.removeEventListener("click", clickListener);
    }

    return () => {
      window.removeEventListener("click", clickListener);
    };
  }, [areaIsOpen]);

  const getPaymentStatus = (invoiceDate, paymentMade, totalInvoiceAmount) => {
    const invoiceDateObj = new Date(invoiceDate);
    console.log(parseInt(paymentMade), parseInt(totalInvoiceAmount));
    if (parseInt(paymentMade) >= parseInt(totalInvoiceAmount)) {
      return (
        <div
          style={{
            color: "#0d8c22",
            fontSize: "14px",
            fontWeight: "600",
            textAlign: "right",
          }}
        >
          Paid
        </div>
      );
    } else if (invoiceDateObj < today) {
      const daysOverdue = Math.floor(
        (today - invoiceDateObj) / (1000 * 60 * 60 * 24)
      );
      return (
        <div
          style={{
            color: "#e62e2e",
            fontSize: "14px",
            fontWeight: "600",
            textAlign: "right",
          }}
        >
          OVERDUE BY {daysOverdue} DAYS
        </div>
      );
    } else {
      const daysDue = Math.floor(
        (invoiceDateObj - today) / (1000 * 60 * 60 * 24)
      );
      return (
        <div
          style={{
            color: "#e62e2e",
            fontSize: "14px",
            fontWeight: "600",
            textAlign: "right",
          }}
        >
          DUE IN {daysDue} DAYS
        </div>
      );
    }
  };

  const renderProducts = (products) => {
    const pages = [];
    const pageCount = Math.ceil((products.length - 7) / 10) + 1;

    for (let i = 0; i < pageCount; i++) {
      const startIndex = i === 0 ? 0 : 7 + (i - 1) * 10;
      const endIndex =
        i === 0
          ? Math.min(7, products.length)
          : Math.min(startIndex + 10, products.length);
      const pageProducts = products.slice(startIndex, endIndex);
      console.log("pagecount: ", pageCount, "i: ", i);

      pages.push(
        <Page size="A4">
          <View style={styles.container}>
            <View fixed style={styles.logoContainer}>
              <Image src={logo} style={styles.logo} />
              <View style={{ marginRight: 100 }}>
                <Text style={styles.textHeavy}>
                  GC Lighting & Bath Solutions Pte Ltd
                </Text>
                <Text style={styles.textSmall}>
                  33 Ubi Avenue 3 #01-59, Vertex, Singapore 408868
                </Text>
                <Text style={styles.textSmall}>
                  Tel: 6322 4300 / 6322 4301 Email: gclightbath@gmail.com
                </Text>
                <Text style={styles.textSmall}>UEN & GST no.: 202303689R</Text>
              </View>
            </View>
            {invoiceData ? (
              <View>
                {i === 0 ? (
                  <>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        marginTop: 30,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 25,
                          fontFamily: "BoldFont",
                        }}
                      >
                        Receipt
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        marginLeft: "5%",
                        width: "90%",
                        marginTop: 20,
                      }}
                    >
                      <View style={{ width: "65%" }}>
                        <Text style={{ fontSize: 12 }}>
                          Bill To: {invoiceData.client_name}
                        </Text>
                        <Text style={{ fontSize: 12 }}>
                          Contact Info: {invoiceData.client_hp}
                        </Text>
                      </View>
                      <View style={{ width: "35%" }}>
                        <Text style={{ fontSize: 12 }}>
                          Invoice No: S.INV{getCurrentYear()}-00
                          {invoiceData.invoice_number}
                        </Text>
                        <Text style={{ fontSize: 12 }}>
                          Quote No: S.Q{getCurrentYear()}-00
                          {invoiceData.quote_number}
                        </Text>
                        <Text style={{ fontSize: 12, marginTop: 3 }}>
                          Date: {getDateForViewing(invoiceData.date).getDate()}/
                          {getDateForViewing(invoiceData.date).getMonth() + 1}/
                          {getDateForViewing(invoiceData.date).getFullYear()}
                        </Text>
                        <Text style={{ fontSize: 12, marginTop: 3 }}>
                          Sales: {invoiceData.sales}
                        </Text>
                        <Text style={{ fontSize: 12, marginTop: 3 }}>
                          Secondary: {invoiceData.secondary_sales}
                        </Text>
                        <Text style={{ fontSize: 12, marginTop: 3 }}>
                          Designer: {invoiceData.designer}
                        </Text>
                        <Text style={{ fontSize: 12, marginTop: 3 }}>
                          Designer Hp: {invoiceData.phone_number}
                        </Text>
                      </View>
                    </View>
                    <View style={{ marginLeft: "5%", marginTop: 15 }}>
                      <Text style={{ fontSize: 12 }}>
                        Jobsite Address: {invoiceData.address}
                      </Text>
                    </View>
                  </>
                ) : null}

                <View
                  style={{
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                    width: "90%",
                    marginLeft: "5%",
                    marginTop: 20,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottom: "2px solid black",
                      height: 35,
                    }}
                  >
                    <View
                      style={{
                        width: "18%",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "BoldFont",
                        }}
                      >
                        SKU
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "30%",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "BoldFont",
                        }}
                      >
                        Product Description
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "16%",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "BoldFont",
                        }}
                      >
                        Area
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "6%",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "BoldFont",
                        }}
                      >
                        Qty
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "12%",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "BoldFont",
                        }}
                      >
                        Unit Price
                      </Text>
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "BoldFont",
                        }}
                      >
                        (SGD)
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "12%",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "BoldFont",
                        }}
                      >
                        Total Price
                      </Text>
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "BoldFont",
                        }}
                      >
                        (SGD)
                      </Text>
                    </View>
                  </View>
                  {pageProducts
                    .slice()
                    .sort((a, b) =>
                      (a.area || "").localeCompare(b.area || "", undefined, {
                        sensitivity: "base",
                      })
                    )
                    .map((product, index) => (
                      <View
                        key={index}
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        <View
                          style={{
                            width: "18%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text style={{ fontSize: 10, textAlign: "center" }}>
                            {product.SKU}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "30%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "left",
                              padding: 5,
                            }}
                          >
                            {product.productDescription
                              ? product.productDescription
                              : "None"}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "16%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text style={{ fontSize: 10, textAlign: "center" }}>
                            {product.area}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "6%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text style={{ fontSize: 10, textAlign: "center" }}>
                            {product.quantity}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "12%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text style={{ fontSize: 10, textAlign: "center" }}>
                            $ {parseFloat(product.UnitPrice).toFixed(2)}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "12%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text style={{ fontSize: 10, textAlign: "center" }}>
                            ${" "}
                            {parseFloat(
                              product.UnitPrice * product.quantity
                            ).toFixed(2)}
                          </Text>
                        </View>
                      </View>
                    ))}
                </View>
                {i === pageCount - 1 ? (
                  <>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        marginLeft: "5%",
                        width: "90%",
                      }}
                    >
                      <View
                        style={{
                          // width: "70%",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      ></View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 20,
                          width: "33.9%",
                          borderLeft: "2px solid black",
                          borderRight: "2px solid black",
                        }}
                      >
                        <View
                          style={{
                            width: "62%",
                            borderRight: "2px solid black",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              fontFamily: "BoldFont",
                            }}
                          >
                            SUB-TOTAL
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "40%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              fontFamily: "BoldFont",
                            }}
                          >
                            $ {calculateTotalValueGiven(invoiceData).toFixed(2)}
                          </Text>
                        </View>
                      </View>
                    </View>

                    {invoiceData.discount || invoiceData.discount !== 0 ? (
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 20,
                          marginLeft: "5%",
                          width: "90%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        ></View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            height: 20,
                            width: "33.9%",
                            borderRight: "2px solid black",
                            borderLeft: "2px solid black",
                          }}
                        >
                          <View
                            style={{
                              width: "62%",
                              borderRight: "2px solid black",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                color: "red",
                                fontFamily: "BoldFont",
                              }}
                            >
                              DISCOUNT
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "40%",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                color: "red",
                                fontFamily: "BoldFont",
                              }}
                            >
                              - $ {invoiceData.discount.toFixed(2)}
                            </Text>
                          </View>
                        </View>
                      </View>
                    ) : (
                      <></>
                    )}

                    {invoiceData.less_cv || invoiceData.less_cv !== 0 ? (
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 20,
                          marginLeft: "5%",
                          width: "90%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        ></View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            height: 20,
                            width: "33.9%",
                            borderRight: "2px solid black",
                            borderLeft: "2px solid black",
                          }}
                        >
                          <View
                            style={{
                              width: "62%",
                              borderRight: "2px solid black",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                color: "red",
                                fontFamily: "BoldFont",
                              }}
                            >
                              VOUCHER
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "40%",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                color: "red",
                                fontFamily: "BoldFont",
                              }}
                            >
                              - $ {invoiceData.less_cv.toFixed(2)}
                            </Text>
                          </View>
                        </View>
                      </View>
                    ) : (
                      <></>
                    )}

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        marginLeft: "5%",
                        width: "90%",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      ></View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 20,
                          width: "33.9%",
                          borderLeft: "2px solid black",
                          borderRight: "2px solid black",
                        }}
                      >
                        <View
                          style={{
                            width: "62%",
                            borderRight: "2px solid black",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              fontFamily: "BoldFont",
                            }}
                          >
                            TOTAL
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "40%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              fontFamily: "BoldFont",
                            }}
                          >
                            ${" "}
                            {(
                              calculateTotalValueGiven(invoiceData) -
                              invoiceData.discount -
                              invoiceData.less_cv
                            ).toFixed(2)}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        marginLeft: "5%",
                        width: "90%",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      ></View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 20,
                          width: "33.9%",
                          borderRight: "2px solid black",
                          borderBottom: "2px solid black",
                          borderLeft: "2px solid black",
                        }}
                      >
                        <View
                          style={{
                            width: "62%",
                            borderRight: "2px solid black",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              fontFamily: "BoldFont",
                            }}
                          >
                            9% GST
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "40%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              fontFamily: "BoldFont",
                            }}
                          >
                            ${" "}
                            {(
                              (calculateTotalValueGiven(invoiceData) -
                                invoiceData.discount -
                                invoiceData.less_cv) *
                              0.09
                            ).toFixed(2)}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        marginLeft: "5%",
                        width: "90%",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      ></View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 20,
                          width: "33.9%",
                          borderRight: "2px solid black",
                          borderBottom: "2px solid black",
                          borderLeft: "2px solid black",
                        }}
                      >
                        <View
                          style={{
                            width: "62%",
                            borderRight: "2px solid black",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              fontFamily: "BoldFont",
                            }}
                          >
                            AMOUNT PAID
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "40%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              fontFamily: "BoldFont",
                            }}
                          >
                            ${" "}
                            {(parseFloat((
                              calculateTotalValueGiven(invoiceData) -
                              invoiceData.discount -
                              invoiceData.less_cv
                            ).toFixed(2)) + parseFloat((
                              (calculateTotalValueGiven(invoiceData) -
                                invoiceData.discount -
                                invoiceData.less_cv) *
                              0.09
                            ).toFixed(2))).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginLeft: "5%",
                        width: "90%",
                        marginTop: "20px",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "left",
                          textDecoration: "underline",
                          fontFamily: "BoldFont",
                        }}
                      >
                        Remarks:
                      </Text>
                      <Text
                        style={{
                          fontSize: 10,
                          textAlign: "left",
                          marginTop: "3px",
                        }}
                      >
                        {invoiceData.remarks}
                      </Text>
                    </View>

                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginLeft: "5%",
                        width: "90%",
                        marginTop: "20px",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "left",
                          textDecoration: "underline",
                          fontFamily: "BoldFont",
                        }}
                      >
                        Terms and Conditions:
                      </Text>
                      <Text
                        style={{
                          fontSize: 10,
                          textAlign: "left",
                          marginTop: "3px",
                        }}
                      >
                        1) All cheques should be crossed and made payable to:{" "}
                        <Text
                          style={{
                            fontFamily: "BoldFont",
                          }}
                        >
                          GC Lighting & Bath Solutions Pte Ltd
                        </Text>
                      </Text>
                      <Text
                        style={{
                          fontSize: 10,
                          textAlign: "left",
                          marginTop: "3px",
                        }}
                      >
                        2) Goods sold cannot be returned & deposit are not
                        refundable
                      </Text>
                      <Text
                        style={{
                          fontSize: 10,
                          textAlign: "left",
                          marginTop: "3px",
                        }}
                      >
                        3) Kindly state "Invoice no." in payment reference when
                        making payment.
                      </Text>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginLeft: "5%",
                        width: "90%",
                        marginTop: "20px",
                      }}
                    >
                      <View
                        style={{
                          border: "1px solid black",
                          padding: "10px",
                          width: "300px",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 12,
                            textAlign: "left",
                            fontWeight: 600,
                            textDecoration: "underline",
                            fontFamily: "BoldFont",
                          }}
                        >
                          The below is our banking details:
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            textAlign: "left",
                            marginTop: "5px",
                          }}
                        >
                          Payee Name: GC Lighting & Bath Solutions Pte Ltd
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            textAlign: "left",
                            marginTop: "3px",
                          }}
                        >
                          Bank: Maybank
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            textAlign: "left",
                            marginTop: "3px",
                          }}
                        >
                          Account No: 04041092841
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            textAlign: "left",
                            marginTop: "3px",
                          }}
                        >
                          Paynow UEN: 202303689R
                        </Text>
                      </View>
                      <View style={{ marginLeft: "00px" }}>
                        <Image src={logo} style={{ height: "100px" }} />
                      </View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "90%",
                        marginLeft: "5%",
                        marginTop: "40px",
                        padding: "30px 0px",
                      }}
                    >
                      <View style={{ width: "150px" }}>
                        <View
                          style={{
                            height: 18,
                            marginTop: "3px",
                            textAlign: "center",
                          }}
                        ></View>
                        <Text
                          style={{
                            fontSize: 10,
                            marginTop: "3px",
                            textAlign: "center",
                            borderTop: "1px solid black",
                          }}
                        >
                          Received by
                        </Text>
                      </View>

                      <View style={{ width: "150px" }}>
                        <Text
                          style={{
                            fontSize: 15,
                            marginTop: "3px",
                            textAlign: "center",
                          }}
                        >
                          {invoiceData.sales}
                        </Text>
                        <Text
                          style={{
                            borderTop: "1px solid black",
                            fontSize: 10,
                            marginTop: "3px",
                            textAlign: "center",
                          }}
                        >
                          Issue by
                        </Text>
                      </View>
                    </View>
                  </>
                ) : null}
              </View>
            ) : null}
          </View>
        </Page>
      );
    }

    return pages;
  };
  const PdfDocument = (
    <Document>
      {invoiceData
        ? invoiceData.product
          ? renderProducts(
            invoiceData.product.sort((a, b) =>
              (a.area || "").localeCompare(b.area || "", undefined, {
                sensitivity: "base",
              })
            )
          )
          : null
        : null}
    </Document>
  );
  const [recordIsOpen, setRecordIsOpen] = useState(false);

  const uniqueSales = [
    ...new Set(filteredInvoice.map((invocie) => invocie.sales)),
  ];

  return (
    <div>
      <Modal
        isOpen={recordIsOpen}
        ariaHideApp={false}
        onRequestClose={() => {
          setRecordIsOpen(false);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            height: "300px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            boxShadow: "none",
            borderRadius: "10px",
            border: "none",
            overflowX: "hidden",
            overflowY: "auto",
            padding: "none",
            display: "flex",
            textAlign: "center",
          },
        }}
      >
        <div
          style={{
            fontSize: "35px",
            color: "#d1ae6e",
            fontWeight: "600",
            marginBottom: "20px",
          }}
        >
          <div style={{ fontSize: "35px" }}> Record payment</div>
          <div style={{ fontSize: "25px" }}>
            for Invoice-{invoiceData ? invoiceData.invoice_number : ""}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          $
          <input
            type="number"
            value={invoiceData ? invoiceData.record_payment : 0}
            onChange={(e) => {
              const invoiceDataCLone = { ...invoiceData };
              invoiceDataCLone.record_payment = e.target.value;
              setInvoiceData(invoiceDataCLone);
            }}
            style={{
              width: "90px",
              height: "35px",
              borderRadius: "10px",
              border: "1px solid lightgrey",
              paddingLeft: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />{" "}
          Paid
        </div>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <button
            style={{
              background: "white",
              padding: "8px 25px",
              color: "#d1ae6e",
              cursor: "pointer",
              borderRadius: "10px",
              border: "1px solid #d1ae6e",
              outline: "none",
              fontSize: "18px",
              marginRight: "10px",
            }}
            onClick={() => {
              setRecordIsOpen(false);
            }}
          >
            Cancel
          </button>
          <button
            style={{
              background: "#d1ae6e",
              padding: "8px 25px",
              color: "white",
              cursor: "pointer",
              borderRadius: "10px",
              border: "none",
              outline: "none",
              fontSize: "18px",
            }}
            onClick={async () => {
              console.log(invoiceData.record_payment);
              try {
                const response = await fetch(
                  "https://www.sales.gclightinggallery.com/laravel/api/createAndUpdateInvoice",
                  {
                    body: JSON.stringify(invoiceData),
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                    method: "POST",
                  }
                );
                if (!response.ok) {
                  // setSignUpError(true);
                  throw new Error("Network response was not ok");
                }

                // Clear the form data after adding the product
              } catch (error) {
                // setSignUpError(true);
                console.error("Error signing up:", error);
              }
              const invoiceIndex = allInvoice.findIndex(
                (invoice) =>
                  invoice.invoice_number === invoiceData.invoice_number
              );
              const changingAllInvoice = [...allInvoice];
              changingAllInvoice[invoiceIndex].record_payment =
                invoiceData.record_payment;
              setAllInvoice(changingAllInvoice);
              setRecordIsOpen(false);
            }}
          >
            Update
          </button>
        </div>
      </Modal>
      <div
        style={{
          padding: "0px 20px",
          height: "7vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            margin: "0px",
            fontSize: "35px",
            fontWeight: "700",
            marginRight: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            setCreatingInvoice(false);
            setinvoiceDataForCondition({});
            setNewInvoice({
              invoice_number: "",
              client_name: "",
              date: formattedDate,
              sales: userData ? userData.name : "",
              designer: "",
              phone_number: "",
              address: "",
              status: "",
              voucher: "",
              discount: 0,
              record_payment: 0,
              less_cv: 0,
              remarks: "",
              product: [],
            });
          }}
        >
          All Receipt
        </div>
      </div>
      {creaatingInvoice ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            fontSize: "13px",
            overflow: "scroll",
            height: "95vh",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "fixed",
              bottom: 0,
              left: "calc(100vw * 0.148)",
              background: "white",
              width: "100%",
              height: "60px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
              display: "flex",
              alignItems: "center",
              padding: "0px 20px",
              zIndex: 50,
            }}
          >
            {invoiceDataForCondition ? (
              allInvoice.some(
                (invoice) =>
                  invoice.invoice_number ===
                  invoiceDataForCondition.invoice_number
              ) ? (
                <>
                  <div
                    style={{
                      background: "rgba(0,0,0,0.05)",
                      padding: "10px 20px",
                      marginRight: "15px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      border: "1px solid lightgrey",
                    }}
                    onClick={async () => {
                      console.log(newInvoice);
                      const invoiceIndex = allInvoice.findIndex(
                        (invoice) =>
                          invoice.invoice_number === newInvoice.invoice_number
                      );

                      if (invoiceIndex !== -1) {
                        const updatedAllInvoice = [...allInvoice];

                        newInvoice.sales = userData.name;

                        updatedAllInvoice[invoiceIndex] = newInvoice;

                        try {
                          const response = await fetch(
                            "https://www.sales.gclightinggallery.com/laravel/api/createAndUpdateInvoice",
                            {
                              body: JSON.stringify(newInvoice),
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`,
                              },
                              method: "POST",
                            }
                          );
                          if (!response.ok) {
                            // setSignUpError(true);
                            throw new Error("Network response was not ok");
                          }

                          // Clear the form data after adding the product
                        } catch (error) {
                          // setSignUpError(true);
                          console.error("Error signing up:", error);
                        }
                        // Update the state with the updated allInvoice array
                        setAllInvoice(updatedAllInvoice);
                        setCreatingInvoice(false);
                        setInvoiceData(newInvoice);
                        console.log(newInvoice);
                        setNewInvoice({
                          invoice_number: "",
                          client_name: "",
                          date: formattedDate,
                          sales: userData ? userData.name : "",
                          designer: "",
                          phone_number: "",
                          address: "",
                          status: "",
                          discount: 0,
                          less_cv: 0,
                          record_payment: 0,
                          voucher: "",
                          remarks: "",
                          product: [],
                        });
                      }
                    }}
                  >
                    Update invoice
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      background: "rgba(0,0,0,0.05)",
                      padding: "10px 20px",
                      marginRight: "15px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      border: "1px solid lightgrey",
                    }}
                    onClick={async () => {
                      // Add the status field to the newInvoice object

                      newInvoice.sales = userData.name;
                      const updatedInvoice = {
                        ...newInvoice,
                        status: 0,
                        invoice_number:
                          getNextInvoiceNumber(allInvoice).toString(),
                      };

                      // Push the updated newInvoice into the allInvoice array
                      const updatedAllInvoice = [...allInvoice, updatedInvoice];

                      try {
                        const response = await fetch(
                          "https://www.sales.gclightinggallery.com/laravel/api/createAndUpdateInvoice",
                          {
                            body: JSON.stringify(updatedInvoice),
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${token}`,
                            },
                            method: "POST",
                          }
                        );
                        if (!response.ok) {
                          // setSignUpError(true);
                          throw new Error("Network response was not ok");
                        }

                        // Clear the form data after adding the product
                      } catch (error) {
                        // setSignUpError(true);
                        console.error("Error signing up:", error);
                      }
                      // Update the state with the updated allInvoice array
                      setAllInvoice(updatedAllInvoice);
                      setCreatingInvoice(false);
                      setInvoiceData(newInvoice);
                      setNewInvoice({
                        invoice_number: "",
                        client_name: "",
                        date: formattedDate,
                        sales: userData ? userData.name : "",
                        designer: "",
                        phone_number: "",
                        voucher: "",
                        address: "",
                        status: "",
                        discount: 0,
                        less_cv: 0,
                        record_payment: 0,
                        remarks: "",
                        product: [],
                      });
                    }}
                  >
                    Save as draft
                  </div>
                  <div
                    style={{
                      background: "#d1ae6e",
                      color: "white",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    onClick={async () => {
                      // Add the status field to the newInvoice object

                      newInvoice.sales = userData.name;
                      const updatedInvoice = {
                        ...newInvoice,
                        status: 1,
                        invoice_number:
                          getNextInvoiceNumber(allInvoice).toString(),
                      };

                      try {
                        const response = await fetch(
                          "https://www.sales.gclightinggallery.com/laravel/api/createAndUpdateInvoice",
                          {
                            body: JSON.stringify(updatedInvoice),
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${token}`,
                            },
                            method: "POST",
                          }
                        );
                        if (!response.ok) {
                          // setSignUpError(true);
                          throw new Error("Network response was not ok");
                        }

                        // Clear the form data after adding the product
                      } catch (error) {
                        // setSignUpError(true);
                        console.error("Error signing up:", error);
                      }
                      // Push the updated newInvoice into the allInvoice array
                      const updatedAllInvoice = [...allInvoice, updatedInvoice];

                      // Update the state with the updated allInvoice array
                      setAllInvoice(updatedAllInvoice);
                      setCreatingInvoice(false);
                      setInvoiceData(newInvoice);
                      setNewInvoice({
                        invoice_number: "",
                        client_name: "",
                        date: formattedDate,
                        sales: userData ? userData.name : "",
                        designer: "",
                        phone_number: "",
                        address: "",
                        status: "",
                        discount: 0,
                        record_payment: 0,
                        voucher: "",
                        less_cv: 0,
                        remarks: "",
                        product: [],
                      });
                    }}
                  >
                    Create Invoice
                  </div>
                </>
              )
            ) : (
              <>
                <div
                  style={{
                    background: "rgba(0,0,0,0.05)",
                    padding: "10px 20px",
                    marginRight: "15px",
                    borderRadius: "10px",
                    cursor: "pointer",
                    border: "1px solid lightgrey",
                  }}
                  onClick={async () => {
                    // Add the status field to the newInvoice object

                    newInvoice.sales = userData.name;
                    const updatedInvoice = {
                      ...newInvoice,
                      status: 0,
                      invoice_number:
                        getNextInvoiceNumber(allInvoice).toString(),
                    };

                    // Push the updated newInvoice into the allInvoice array
                    const updatedAllInvoice = [...allInvoice, updatedInvoice];

                    try {
                      const response = await fetch(
                        "https://www.sales.gclightinggallery.com/laravel/api/createAndUpdateInvoice",
                        {
                          body: JSON.stringify(updatedInvoice),
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                          },
                          method: "POST",
                        }
                      );
                      if (!response.ok) {
                        // setSignUpError(true);
                        throw new Error("Network response was not ok");
                      }

                      // Clear the form data after adding the product
                    } catch (error) {
                      // setSignUpError(true);
                      console.error("Error signing up:", error);
                    }

                    // Update the state with the updated allInvoice array
                    setAllInvoice(updatedAllInvoice);
                    setCreatingInvoice(false);
                    setInvoiceData(newInvoice);
                    setNewInvoice({
                      invoice_number: "",
                      client_name: "",
                      date: formattedDate,
                      sales: userData ? userData.name : "",
                      designer: "",
                      phone_number: "",
                      address: "",
                      voucher: "",
                      status: "",
                      discount: 0,
                      record_payment: 0,
                      less_cv: 0,
                      remarks: "",
                      product: [],
                    });
                  }}
                >
                  Save as draft
                </div>
                <div
                  style={{
                    background: "#d1ae6e",
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    // Add the status field to the newInvoice object

                    newInvoice.sales = userData.name;
                    const updatedInvoice = {
                      ...newInvoice,
                      status: 1,
                      invoice_number:
                        getNextInvoiceNumber(allInvoice).toString(),
                    };

                    try {
                      const response = await fetch(
                        "https://www.sales.gclightinggallery.com/laravel/api/createAndUpdateInvoice",
                        {
                          body: JSON.stringify(updatedInvoice),
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                          },
                          method: "POST",
                        }
                      );
                      if (!response.ok) {
                        // setSignUpError(true);
                        throw new Error("Network response was not ok");
                      }

                      // Clear the form data after adding the product
                    } catch (error) {
                      // setSignUpError(true);
                      console.error("Error signing up:", error);
                    }

                    // Push the updated newInvoice into the allInvoice array
                    const updatedAllInvoice = [...allInvoice, updatedInvoice];

                    // Update the state with the updated allInvoice array
                    setAllInvoice(updatedAllInvoice);
                    setCreatingInvoice(false);
                    setInvoiceData(newInvoice);
                    setNewInvoice({
                      invoice_number: "",
                      client_name: "",
                      date: formattedDate,
                      sales: userData ? userData.name : "",
                      designer: "",
                      phone_number: "",
                      address: "",
                      status: "",
                      discount: 0,
                      less_cv: 0,
                      record_payment: 0,
                      voucher: "",
                      remarks: "",
                      product: [],
                    });
                  }}
                >
                  Create Invoice
                </div>
              </>
            )}
          </div>
          <div
            style={{
              width: "100%",
              borderTop: "1px solid lightgrey",
              padding: "20px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "150px" }}>Customer Name</div>
              <div
                style={{ display: "flex", position: "relative" }}
                id="show_client_trigger"
              >
                <div
                  style={{
                    width: "350px",
                    height: "35px",
                    borderRadius: "10px 0px 0px 10px",
                    border: "1px solid lightgrey",
                    paddingLeft: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowClientList(!showClientList)}
                >
                  <div> {newInvoice.client_name}</div>
                  <div>
                    <KeyboardArrowDownIcon />
                  </div>
                </div>
                <div
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "0px 10px 10px 0px",
                    border: "1px solid rgb(209, 174, 110)",
                    background: "rgb(209, 174, 110)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SearchIcon style={{ color: "white" }} />
                </div>
                {showClientList ? (
                  <div
                    id="show_client_container"
                    style={{
                      position: "absolute",
                      top: 40,
                      left: 0,
                      height: "350px",
                      width: "400px",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 10px lightgrey",
                      zIndex: "50",
                      background: "white",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <input
                        className="search_input"
                        placeholder="Search customer"
                        type="text"
                        value={searchQueryClient}
                        onChange={handleSearchClientInputChange}
                        style={{
                          padding: "10px 12px",
                          width: "calc(100% - 24px)",
                          border: "none",
                          background: "#f0eeeb",
                          borderRadius: "8px",
                          marginRight: "5px",
                        }}
                      />
                      <div style={{ marginTop: "5px" }}>
                        {filteredClient.map((client, index) => (
                          <div
                            key={index}
                            className="client_select_dropdown"
                            style={{ padding: "5px 10px" }}
                            onClick={() => {
                              setNewInvoice({
                                ...newInvoice,
                                client_name: client.name,
                              });
                              setShowClientList(false);
                            }}
                          >
                            {client.name}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "calc(100% - 24px)",
                        padding: "10px 12px",
                        background: "rgb(209, 174, 110)",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        setNewInvoice({
                          ...newInvoice,
                          client_name: searchQueryClient,
                        });
                        setShowClientList(false);
                      }}
                    >
                      <AddCircleIcon
                        style={{ color: "white", marginRight: "10px" }}
                      />{" "}
                      New Customer
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <div style={{ width: "150px" }}>Invoice number</div>
              <div>
                <div
                  type="text"
                  style={{
                    width: "350px",
                    height: "35px",
                    borderRadius: "10px",
                    border: "1px solid lightgrey",
                    paddingLeft: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {newInvoice.invoice_number
                    ? `INVOICE-${newInvoice.invoice_number}`
                    : `INVOICE-${getNextInvoiceNumber(allInvoice)}`}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <div style={{ width: "150px" }}>Invoice Date</div>
              <div>
                <input
                  type="date"
                  value={newInvoice.date}
                  onChange={(e) =>
                    setNewInvoice({ ...newInvoice, date: e.target.value })
                  }
                  placeholder="Date"
                  style={{
                    width: "340px",
                    height: "35px",
                    borderRadius: "10px",
                    border: "1px solid lightgrey",
                    padding: "0px 10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <div style={{ width: "150px" }}>Sales</div>
                <div>
                  <input
                    type="text"
                    value={userData ? userData.name : ""}
                    // onChange={(e) =>
                    //   setNewInvoice({ ...newInvoice, sales: e.target.value })
                    // }
                    placeholder="Sales"
                    style={{
                      width: "350px",
                      height: "35px",
                      borderRadius: "10px",
                      border: "1px solid lightgrey",
                      paddingLeft: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    disabled
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginLeft: "25px",
                }}
              >
                <div style={{ width: "150px" }}>Designer</div>
                <div>
                  <input
                    type="text"
                    value={newInvoice.designer}
                    onChange={(e) =>
                      setNewInvoice({ ...newInvoice, designer: e.target.value })
                    }
                    placeholder="Designer"
                    style={{
                      width: "500px",
                      height: "35px",
                      borderRadius: "10px",
                      border: "1px solid lightgrey",
                      paddingLeft: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  />
                </div>
              </div>
            </div>

            <div style={{ display: "flex", marginTop: "10px" }}>
              <div style={{ width: "150px" }}>Phone Number</div>
              <div>
                <input
                  type="number"
                  value={newInvoice.phone_number}
                  onChange={(e) =>
                    setNewInvoice({
                      ...newInvoice,
                      phone_number: e.target.value,
                    })
                  }
                  placeholder="Phone number"
                  style={{
                    width: "350px",
                    height: "35px",
                    borderRadius: "10px",
                    border: "1px solid lightgrey",
                    paddingLeft: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <div style={{ width: "150px" }}>Address</div>
              <div>
                <input
                  type="text"
                  value={newInvoice.address}
                  onChange={(e) =>
                    setNewInvoice({ ...newInvoice, address: e.target.value })
                  }
                  placeholder="Address"
                  style={{
                    width: "60vw",
                    height: "35px",
                    borderRadius: "10px",
                    border: "1px solid lightgrey",
                    paddingLeft: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                paddingTop: "20px",
                width: "100%",
                borderTop: "1px solid lightgrey",
              }}
            >
              <div style={{ width: "150px", marginBottom: "10px" }}>
                Products
              </div>
              <div style={{ border: "1px solid lightgrey" }}>
                <div style={{ display: "flex", padding: "10px 0px" }}>
                  <div style={{ width: "450px", marginLeft: "10px" }}>
                    Item details
                  </div>
                  <div style={{ width: "120px", marginLeft: "50px" }}>Area</div>
                  <div style={{ width: "80px", marginLeft: "50px" }}>
                    Quantity
                  </div>
                  <div style={{ width: "80px", marginLeft: "50px" }}>Rate</div>
                  <div style={{ width: "100px", marginLeft: "50px" }}>
                    Amount
                  </div>
                  <div style={{ width: "90px", marginLeft: "50px" }}>
                    Category
                  </div>
                  <div style={{ width: "90px", marginLeft: "50px" }}>Tag</div>
                </div>
                {newInvoice.product
                  .slice()
                  .sort((a, b) =>
                    (a.area || "").localeCompare(b.area || "", undefined, {
                      sensitivity: "base",
                    })
                  )
                  .map((product, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        borderTop: "1px solid lightgrey",
                        padding: "10px 0px",
                      }}
                    >
                      <div style={{ width: "450px", marginLeft: "10px" }}>
                        {/* <input
                          type="text"
                          value={product.productName}
                          onChange={(e) => {
                            const updatedProducts = newInvoice.product.map(
                              (p) =>
                                p.SKU === product.SKU
                                  ? { ...p, productName: e.target.value }
                                  : p
                            );

                            setNewInvoice({
                              ...newInvoice,
                              product: updatedProducts,
                            });
                          }}
                          placeholder="Product name"
                          style={{
                            width: "100%",
                            height: "35px",
                            borderRadius: "10px",
                            border: "1px solid lightgrey",
                            paddingLeft: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        /> */}
                        <div style={{ fontSize: "18px", fontWeight: "600" }}>
                          {product.productName}
                        </div>
                        <div style={{ fontSize: "12px", fontWeight: "400" }}>
                          {product.productDescription
                            ? product.productDescription.slice(0, 30) +
                            (product.productDescription.length > 30
                              ? " ..."
                              : "")
                            : "None"}
                        </div>
                        {/* <textarea
                          type="text"
                          value={product.productDescription}
                          onChange={(e) => {
                            const updatedProducts = newInvoice.product.map(
                              (p) =>
                                p.SKU === product.SKU
                                  ? { ...p, productDescription: e.target.value }
                                  : p
                            );

                            setNewInvoice({
                              ...newInvoice,
                              product: updatedProducts,
                            });
                          }}
                          placeholder="Product description"
                          style={{
                            width: "100%",
                            height: "35px",
                            borderRadius: "10px",
                            border: "1px solid lightgrey",
                            paddingLeft: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            resize: "none",
                          }}
                        /> */}
                      </div>
                      <div style={{ width: "120px", marginLeft: "50px" }}>
                        {/* <div
                          style={{ display: "flex", position: "relative" }}
                          id={`show_area_trigger_${index}`}
                        >
                          <div
                            style={{
                              width: "350px",
                              height: "35px",
                              borderRadius: "10px",
                              border: "1px solid lightgrey",
                              paddingLeft: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              console.log(filteredArea);
                              toggleDropdown(index);
                            }}
                          >
                            <div> {product.area}</div>
                            <div>
                              <KeyboardArrowDownIcon />
                            </div>
                          </div>
                          {areaIsOpen[index] ? (
                            <div
                              id={`show_area_container_${index}`}
                              style={{
                                position: "absolute",
                                top: 40,
                                left: 0,
                                height: "250px",
                                width: "300px",
                                borderRadius: "10px",
                                boxShadow: "0px 0px 10px lightgrey",
                                zIndex: "50",
                                background: "white",
                                padding: "5px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <input
                                  className="search_input"
                                  placeholder="Search area"
                                  type="text"
                                  value={searchAreaClient}
                                  onChange={handleSearchAreaInputChange}
                                  style={{
                                    padding: "10px 12px",
                                    width: "calc(100% - 24px)",
                                    border: "none",
                                    background: "#f0eeeb",
                                    borderRadius: "8px",
                                    marginRight: "5px",
                                  }}
                                />
                                <div style={{ marginTop: "5px" }}>
                                  {Object.keys(filteredArea).map(
                                    (area, index) => (
                                      <div
                                        key={index}
                                        className="client_select_dropdown"
                                        style={{ padding: "5px 10px" }}
                                        onClick={() => {
                                          const updatedProducts =
                                            newInvoice.product.map((p) =>
                                              p.SKU === product.SKU
                                                ? {
                                                    ...p,
                                                    area: filteredArea[area],
                                                  }
                                                : p
                                            );
                                          setNewInvoice({
                                            ...newInvoice,
                                            product: updatedProducts,
                                          });
                                          setAreaIsOpen(
                                            Array(
                                              newInvoice.product.length
                                            ).fill(false)
                                          );
                                        }}
                                      >
                                        {filteredArea[area]}
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "calc(100% - 24px)",
                                  padding: "10px 12px",
                                  background: "rgb(209, 174, 110)",
                                  color: "white",
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  borderRadius: "10px",
                                }}
                                onClick={() => {
                                  const updatedProducts =
                                    newInvoice.product.map((p) =>
                                      p.SKU === product.SKU
                                        ? {
                                            ...p,
                                            area: searchAreaClient,
                                          }
                                        : p
                                    );
                                  setNewInvoice({
                                    ...newInvoice,
                                    product: updatedProducts,
                                  });
                                  setAreaIsOpen(
                                    Array(newInvoice.product.length).fill(false)
                                  );
                                  setSearchAreaClient("");
                                }}
                              >
                                <AddCircleIcon
                                  style={{
                                    color: "white",
                                    marginRight: "10px",
                                  }}
                                />{" "}
                                Add New Area
                              </div>
                            </div>
                          ) : null}
                        </div> */}
                        {product.area}
                      </div>
                      <div style={{ width: "80px", marginLeft: "50px" }}>
                        {/* <input
                          type="number"
                          value={product.quantity}
                          onChange={(e) => {
                            const updatedProducts = newInvoice.product.map(
                              (p) =>
                                p.SKU === product.SKU
                                  ? { ...p, quantity: e.target.value }
                                  : p
                            );

                            setNewInvoice({
                              ...newInvoice,
                              product: updatedProducts,
                            });
                          }}
                          placeholder="0"
                          style={{
                            width: "100%",
                            height: "35px",
                            borderRadius: "10px",
                            border: "1px solid lightgrey",
                            paddingLeft: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        /> */}
                        {product.quantity}
                      </div>
                      <div style={{ width: "80px", marginLeft: "50px" }}>
                        {/* <input
                          type="number"
                          value={product.UnitPrice}
                          onChange={(e) => {
                            const updatedProducts = newInvoice.product.map(
                              (p) =>
                                p.SKU === product.SKU
                                  ? { ...p, UnitPrice: e.target.value }
                                  : p
                            );

                            setNewInvoice({
                              ...newInvoice,
                              product: updatedProducts,
                            });
                          }}
                          placeholder="0.00"
                          style={{
                            width: "100%",
                            height: "35px",
                            borderRadius: "10px",
                            border: "1px solid lightgrey",
                            paddingLeft: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        /> */}
                        $ {product.UnitPrice}
                      </div>
                      <div style={{ width: "100px", marginLeft: "50px" }}>
                        ${" "}
                        {isNaN(product.quantity * product.UnitPrice) ||
                          product.quantity * product.UnitPrice === 0
                          ? "0.00"
                          : (product.quantity * product.UnitPrice).toFixed(2)}
                      </div>

                      <div style={{ width: "90px", marginLeft: "50px" }}>
                        {product.Category}
                      </div>

                      <div style={{ width: "90px", marginLeft: "50px" }}>
                        {product.Tag}
                      </div>

                      {/* <div style={{}}>
                        <RemoveCircleOutlineIcon
                          className="remove_invoice_product"
                          style={{
                            cursor: "pointer",
                            transition: "0.4s all ease-in-out",
                            fontSize: "30px",
                          }}
                          onClick={() => {
                            const updatedNewInvoice = [...newInvoice.product];
                            updatedNewInvoice.splice(index, 1);

                            setNewInvoice({
                              ...newInvoice,
                              product: updatedNewInvoice,
                            });
                          }}
                        />
                      </div> */}
                    </div>
                  ))}
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "500px", position: "relative" }}>
                  {/* <div style={{}}>
                    <div
                      id="show_product_trigger"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        background: "#d1ae6e",
                        width: "200px",
                        padding: "10px 20px",
                        borderRadius: "10px",
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        // const updatedProduct = [...newInvoice.product];
                        // const newProduct = {
                        //   invoice_number: "",
                        //   client_name: "",
                        //   date: "",
                        //   sales: "",
                        //   designer: "",
                        //   phone_number: "",
                        //   address: "",
                        //   status: "",
                        //   product: [],
                        // };
                        // updatedProduct.push(newProduct);
                        // setNewInvoice({ ...newInvoice, product: updatedProduct });
                        console.log(newInvoice);
                        setShowProductList(!showProductList);
                      }}
                    >
                      <AddCircleIcon />
                      Add New Product
                    </div>
                  </div> */}
                  <div style={{ marginTop: "10px" }}>
                    <textarea
                      type="text"
                      value={newInvoice.remarks}
                      onChange={(e) =>
                        setNewInvoice({
                          ...newInvoice,
                          remarks: e.target.value,
                        })
                      }
                      placeholder="Remarks"
                      style={{
                        width: "90%",
                        height: "150px",
                        borderRadius: "10px",
                        border: "1px solid lightgrey",
                        paddingLeft: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        resize: "none",
                      }}
                    />
                  </div>

                  {/* {showProductList ? (
                    <div
                      id="show_product_container"
                      style={{
                        position: "absolute",
                        top: 50,
                        left: 0,
                        height: "300px",
                        width: "400px",
                        borderRadius: "10px",
                        boxShadow: "0px 0px 10px lightgrey",
                        zIndex: "50",
                        background: "white",
                        padding: "5px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <input
                          className="search_input"
                          placeholder="Search product"
                          type="text"
                          value={searchQueryProduct}
                          onChange={handleSearchProductInputChange}
                          style={{
                            padding: "10px 12px",
                            width: "calc(100% - 24px)",
                            border: "none",
                            background: "#f0eeeb",
                            borderRadius: "8px",
                            marginRight: "5px",
                          }}
                        />
                        <div
                          style={{
                            marginTop: "5px",
                            height: "250px",
                            overflowY: "scroll",
                          }}
                        >
                          {filteredProduct.map((product, index) => (
                            <div
                              key={index}
                              className="client_select_dropdown"
                              style={{ padding: "5px 10px" }}
                              onClick={() => {
                                const updatedProduct = [...newInvoice.product];
                                const newProduct = {
                                  Brand: product.brand,
                                  Category: product.categories,
                                  CreatedAt: product.created_at,
                                  productDescription: product.description,
                                  Height: product.height,
                                  id: product.id,
                                  productImage: product.img_src,
                                  Length: product.length,
                                  AttributeColor: product.luminance,
                                  productName: product.name,
                                  Size: product.size,
                                  SKU: product.sku,
                                  Stock: product.stock,
                                  Tag: product.tags,
                                  UnitPrice: product.unit_price,
                                  UpdatedAt: product.updated_at,
                                  Weight: product.weight,
                                  Width: product.width,
                                  quantity: 1,
                                  area: "",
                                };
                                updatedProduct.push(newProduct);
                                setNewInvoice({
                                  ...newInvoice,
                                  product: updatedProduct,
                                });
                                setShowProductList(false);
                              }}
                            >
                              {product.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                </div>
                <div style={{ width: "500px" }}>
                  <div
                    style={{
                      background: "rgba(0,0,0,0.03)",
                      width: "100%",
                      borderRadius: "10px",
                      marginTop: "10px",
                      paddingBottom: "20px",
                      border: "1px solid lightgrey",
                      marginBottom: "80px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          marginLeft: "20px",
                          marginTop: "20px",
                        }}
                      >
                        Subtotal
                      </div>
                      <div style={{ marginRight: "40px", marginTop: "20px" }}>
                        $ {calculateTotalValue().toFixed(2)}
                      </div>
                    </div>
                    {newInvoice.discount ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "20px",
                        }}
                      >
                        <div
                          style={{
                            width: "300px",
                            marginLeft: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <div style={{ display: "flex" }}>
                              Discount
                              <input
                                type="checkbox"
                                checked={discountIsOpen}
                                onChange={(e) => {
                                  setDiscountIsOpen(e.target.checked);
                                  if (e.target.checked === false) {
                                    console.log("it is false");
                                    setNewInvoice({
                                      ...newInvoice,
                                      discount: 0,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div
                              style={{
                                color: "#d1ae6e",
                                fontSize: "12px",
                                marginTop: "2px",
                              }}
                            >
                              Apply in deducting amount
                            </div>
                          </div>
                          {discountIsOpen ? (
                            <input
                              value={newInvoice.discount}
                              onChange={(e) => {
                                const value = parseInt(e.target.value, 10); // Parse the input value to an integer

                                setNewInvoice({
                                  ...newInvoice,
                                  discount: isNaN(value) ? 0 : value, // Set the discount value as an integer or an empty string
                                });
                              }}
                              style={{
                                width: "100px",
                                height: "35px",
                                borderRadius: "10px",
                                border: "1px solid lightgrey",
                                paddingLeft: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            />
                          ) : newInvoice.discount ? (
                            <input
                              value={newInvoice.discount}
                              onChange={(e) => {
                                const value = parseInt(e.target.value, 10); // Parse the input value to an integer

                                setNewInvoice({
                                  ...newInvoice,
                                  discount: isNaN(value) ? 0 : value, // Set the discount value as an integer or an empty string
                                });
                              }}
                              style={{
                                width: "100px",
                                height: "35px",
                                borderRadius: "10px",
                                border: "1px solid lightgrey",
                                paddingLeft: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        {discountIsOpen ? (
                          <div style={{ marginRight: "40px" }}>
                            ${" "}
                            {(
                              calculateTotalValue() - newInvoice.discount
                            ).toFixed(2)}
                          </div>
                        ) : newInvoice.discount ? (
                          <div style={{ marginRight: "40px" }}>
                            ${" "}
                            {(
                              calculateTotalValue() - newInvoice.discount
                            ).toFixed(2)}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {newInvoice.less_cv ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "20px",
                        }}
                      >
                        <div
                          style={{
                            width: "300px",
                            marginLeft: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <div style={{ display: "flex" }}>
                              Voucher
                              {/* <input
                              type="checkbox"
                              checked={voucherIsOpen}
                              onChange={(e) => {
                                setVoucherIsOpen(e.target.checked);
                                if (e.target.checked === false) {
                                  console.log("it is false");
                                  setNewInvoice({
                                    ...newInvoice,
                                    voucher: "",
                                    less_cv: 0,
                                  });
                                }
                              }}
                            /> */}
                            </div>
                            {/* <div
                            style={{
                              color: "#d1ae6e",
                              fontSize: "12px",
                              marginTop: "2px",
                            }}
                          >
                            Apply voucher in deducting amount
                          </div> */}
                          </div>
                          {voucherIsOpen ? (
                            <input
                              placeholder="Voucher ID"
                              value={newInvoice.voucher}
                              onChange={(e) => {
                                setNewInvoice({
                                  ...newInvoice,
                                  voucher: e.target.value,
                                });
                              }}
                              disabled
                              style={{
                                width: "100px",
                                height: "35px",
                                borderRadius: "10px",
                                border: "1px solid lightgrey",
                                paddingLeft: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            />
                          ) : newInvoice.less_cv ? (
                            <input
                              placeholder="Voucher ID"
                              value={newInvoice.voucher}
                              onChange={(e) => {
                                setNewInvoice({
                                  ...newInvoice,
                                  voucher: e.target.value,
                                });
                              }}
                              disabled
                              style={{
                                width: "100px",
                                height: "35px",
                                borderRadius: "10px",
                                border: "1px solid lightgrey",
                                paddingLeft: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            />
                          ) : (
                            <></>
                          )}
                          {voucherIsOpen ? (
                            <input
                              value={newInvoice.less_cv}
                              onChange={(e) => {
                                const value = parseInt(e.target.value, 10); // Parse the input value to an integer

                                setNewInvoice({
                                  ...newInvoice,
                                  less_cv: isNaN(value) ? 0 : value,
                                });
                              }}
                              disabled
                              style={{
                                width: "50px",
                                height: "35px",
                                borderRadius: "10px",
                                border: "1px solid lightgrey",
                                paddingLeft: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            />
                          ) : newInvoice.less_cv ? (
                            <input
                              value={newInvoice.less_cv}
                              onChange={(e) => {
                                const value = parseInt(e.target.value, 10); // Parse the input value to an integer

                                setNewInvoice({
                                  ...newInvoice,
                                  less_cv: isNaN(value) ? 0 : value,
                                });
                              }}
                              disabled
                              style={{
                                width: "50px",
                                height: "35px",
                                borderRadius: "10px",
                                border: "1px solid lightgrey",
                                paddingLeft: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        {voucherIsOpen ? (
                          <div style={{ marginRight: "40px" }}>
                            ${" "}
                            {(
                              calculateTotalValue() -
                              newInvoice.discount -
                              newInvoice.less_cv
                            ).toFixed(2)}
                          </div>
                        ) : newInvoice.less_cv ? (
                          <div style={{ marginRight: "40px" }}>
                            ${" "}
                            {(
                              calculateTotalValue() -
                              newInvoice.discount -
                              newInvoice.less_cv
                            ).toFixed(2)}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <div style={{ width: "150px", marginLeft: "20px" }}>
                        Total
                      </div>
                      <div style={{ marginRight: "40px" }}>
                        ${" "}
                        {(
                          calculateTotalValue() -
                          newInvoice.discount -
                          newInvoice.less_cv
                        ).toFixed(2)}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <div style={{ width: "150px", marginLeft: "20px" }}>
                        GST
                      </div>
                      <div style={{ marginRight: "40px" }}>
                        ${" "}
                        {(
                          (calculateTotalValue() -
                            newInvoice.discount -
                            newInvoice.less_cv) *
                          0.09
                        ).toFixed(2)}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      <div style={{ width: "250px", marginLeft: "20px" }}>
                        Account payable
                      </div>
                      <div
                        style={{
                          marginRight: "40px",
                          fontSize: "25px",
                          fontWeight: "700",
                        }}
                      >
                        ${" "}
                        {(
                          (calculateTotalValue() -
                            newInvoice.discount -
                            newInvoice.less_cv) *
                          1.09
                        ).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ color: "transparent", height: "200px" }}>asd</div>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ width: "25%", borderTop: "1px solid lightgrey" }}>
            <div
              style={{
                padding: "0px 10px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottom: "1px solid lightgrey",
                // background:"red"
              }}
            >
              <input
                className="search_input"
                placeholder="Search all Receipt"
                type="text"
                value={searchQuery}
                onChange={handleSearchInputChange}
                style={{
                  padding: "8px 20px",
                  width: "100%",
                  border: "none",
                  background: "#f0eeeb",
                  borderRadius: "8px",
                  marginRight: "5px",
                }}
              />
            </div>
            {userData ? (
              userData.role !== "Sales" ? (
                <div
                  style={{
                    height: "5vh",
                    borderBottom: "1px solid lightgrey",
                    padding: "10px 10px 5px 10px",
                  }}
                >
                  <select
                    style={{
                      padding: "8px 20px",
                      width: "100%",
                      border: "none",
                      background: "#f0eeeb",
                      borderRadius: "8px",
                    }}
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.target.value)}
                  >
                    <option value="">Everybody</option>
                    {uniqueSales.map((sales) => (
                      <option key={sales} value={sales}>
                        {sales}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null
            ) : null}
            <div
              style={{
                overflowY: "scroll",
                height: userData
                  ? userData.role !== "Sales"
                    ? "73vh"
                    : "78vh"
                  : "78vh",
              }}
            >
              {filteredInvoice
                .filter(
                  (invoice) =>
                    selectedUser === "" || invoice.sales === selectedUser
                )
                .map((invoice, index) => {
                  if (userData && userData.role === "Sales") {
                    if (parseFloat(invoice.input_by) === userId) {
                      if (
                        parseFloat(invoice.record_payment) >=
                        parseFloat(
                          (
                            (calculateTotalValueGiven(invoice) -
                              invoice.discount -
                              invoice.less_cv) *
                            1.09
                          ).toFixed(2)
                        )
                      ) {
                        return (
                          <div
                            className="invoice_item"
                            key={index}
                            onClick={() => {
                              console.log(invoice);
                              setInvoiceData(invoice);
                            }}
                            style={{
                              background:
                                invoiceData === invoice ? `#f2efeb` : "white",
                              padding: "8px 0px",
                              borderBottom: "1px solid lightgrey",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "0px 15px",
                              }}
                            >
                              <div style={{ fontSize: "18px" }}>
                                {invoice.client_name}
                              </div>
                              <div>
                                SGD{" "}
                                {(
                                  (calculateTotalValueGiven(invoice) -
                                    invoice.discount -
                                    invoice.less_cv) *
                                  1.09
                                ).toFixed(2)}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                padding: "0px 15px",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "13px",
                                  color: "#d1ae6e",
                                  fontWeight: "600",
                                }}
                              >
                                {invoice.status === 1
                                  ? `${invoiceData
                                    ? "S.R" +
                                    getCurrentYear() +
                                    "-00" +
                                    invoiceData.invoice_number
                                    : null
                                  }`
                                  : invoice.status === 0
                                    ? "Draft"
                                    : "Credit Note"}
                              </div>
                              {/* <div style={{}}>
                          {getPaymentStatus(
                            invoice.date,
                            parseInt(invoice.record_payment),
                            invoice.product
                              .reduce(
                                (acc, item) =>
                                  acc +
                                  parseFloat(item.quantity) *
                                    parseFloat(item.UnitPrice),
                                0
                              )
                              .toFixed(2)
                          )}
                        </div> */}
                            </div>
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    } else {
                      return <></>;
                    }
                  } else {
                    if (
                      parseFloat(invoice.record_payment) >=
                      parseFloat(
                        (
                          (calculateTotalValueGiven(invoice) -
                            invoice.discount -
                            invoice.less_cv) *
                          1.09
                        ).toFixed(2)
                      )
                    ) {
                      return (
                        <div
                          className="invoice_item"
                          key={index}
                          onClick={() => {
                            console.log(invoice);
                            setInvoiceData(invoice);
                          }}
                          style={{
                            background:
                              invoiceData === invoice ? `#f2efeb` : "white",
                            padding: "8px 0px",
                            borderBottom: "1px solid lightgrey",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "0px 15px",
                            }}
                          >
                            <div style={{ fontSize: "18px" }}>
                              {invoice.client_name}
                            </div>
                            <div>
                              SGD{" "}
                              {(parseFloat((
                                calculateTotalValueGiven(invoice) -
                                invoice.discount -
                                invoice.less_cv
                              ).toFixed(2)) +
                                parseFloat((
                                  (calculateTotalValueGiven(invoice) -
                                    invoice.discount -
                                    invoice.less_cv) *
                                  0.09
                                ).toFixed(2))).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              padding: "0px 15px",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "13px",
                                color: "#d1ae6e",
                                fontWeight: "600",
                              }}
                            >
                              {invoice.status === 1
                                ? ` 
                                ${"S.R" +
                                getCurrentYear() +
                                "-00" +
                                invoice.invoice_number
                                }`
                                : invoice.status === 0
                                  ? "Draft"
                                  : "Credit Note"}
                            </div>
                            {/* <div style={{}}>
                          {getPaymentStatus(
                            invoice.date,
                            parseInt(invoice.record_payment),
                            invoice.product
                              .reduce(
                                (acc, item) =>
                                  acc +
                                  parseFloat(item.quantity) *
                                    parseFloat(item.UnitPrice),
                                0
                              )
                              .toFixed(2)
                          )}
                        </div> */}
                          </div>
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  }
                })}
            </div>
          </div>
          <div
            style={{
              width: "75%",
              borderTop: "1px solid lightgrey",
              borderLeft: "1px solid lightgrey",
            }}
          >
            <div
              style={{
                display: "flex",
                height: "50px",
                alignItems: "center",
                borderBottom: "1px solid lightgrey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 20px",
                  borderRight: "1px solid lightgrey",
                  height: "50px",
                }}
              >
                <div
                  className="remove_text_decoration"
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  {invoiceData
                    ? "S.R" +
                    getCurrentYear() +
                    "-00" +
                    invoiceData.invoice_number
                    : null}
                </div>
              </div>

              {/* {invoiceData ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 20px",
                    borderRight: "1px solid lightgrey",
                    height: "50px",
                  }}
                >
                  <MailOutlineIcon
                    style={{
                      color: "rgba(0,0,0,0.7)",
                      fontSize: "18px",
                      marginRight: "4px",
                    }}
                  />
                  <div
                    className="remove_text_decoration"
                    style={{
                      color: "rgba(0,0,0,0.7)",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Send Mail
                  </div>
                </div>
              ) : null} */}

              {invoiceData ? (
                <PDFDownloadLink
                  document={PdfDocument}
                  fileName={invoiceFileName}
                  style={{ textDecoration: "none" }}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <></>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "0px 20px",
                          borderRight: "1px solid lightgrey",
                          height: "50px",
                        }}
                      >
                        <PictureAsPdfIcon
                          style={{
                            color: "rgba(0,0,0,0.7)",
                            fontSize: "18px",
                            marginRight: "4px",
                          }}
                        />
                        <div
                          className="remove_text_decoration"
                          style={{
                            color: "rgba(0,0,0,0.7)",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          PDF Download
                        </div>
                      </div>
                    )
                  }
                </PDFDownloadLink>
              ) : null}
              {invoiceData ? (
                invoiceData.status === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 20px",
                      borderRight: "1px solid lightgrey",
                      height: "50px",
                      color: "rgba(0,0,0,0.7)",
                      fontWeight: "600",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      updateInvoiceStatus({
                        ...invoiceData,
                        status: 1,
                      });
                    }}
                  >
                    Create Invoice
                  </div>
                ) : null
              ) : null}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                overflowY: "scroll",
                height: "78vh",
              }}
            >
              {invoiceData ? (
                <div
                  style={{
                    marginTop: "30px",
                    width: "600px",
                    // height: "150vh",
                    // boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      justifyContent: "space-between",
                    }}
                  >
                    <img src={logo} style={styles.logo} />
                    <div style={{ marginRight: 100 }}>
                      <div style={styles.textHeavy}>
                        GC Lighting & Bath Solutions Pte Ltd
                      </div>
                      <div style={styles.textSmall}>
                        33 Ubi Avenue 3 #01-59, Vertex, Singapore 408868
                      </div>
                      <div style={styles.textSmall}>
                        Tel: 6322 4300 / 6322 4301 Email: gclightbath@gmail.com
                      </div>
                      <div style={styles.textSmall}>
                        UEN & GST no.: 202303689R
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: 30,
                    }}
                  >
                    <div style={{ fontSize: 25, fontWeight: "bolder" }}>
                      Receipt
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginLeft: "5%",
                      width: "90%",
                      marginTop: 20,
                    }}
                  >
                    <div style={{ width: "65%" }}>
                      <div style={{ fontSize: 12 }}>
                        Bill To: {invoiceData.client_name}
                      </div>
                      <div style={{ fontSize: 12 }}>
                        Contact Info: {invoiceData.client_hp}
                      </div>
                    </div>
                    <div style={{ width: "35%" }}>
                      <div style={{ fontSize: 12 }}>
                        Invoice No: S.INV{getCurrentYear()}-00
                        {invoiceData.invoice_number}
                      </div>
                      <div style={{ fontSize: 12, marginTop: 3 }}>
                        Date: {getDateForViewing(invoiceData.date).getDate()}/
                        {getDateForViewing(invoiceData.date).getMonth() + 1}/
                        {getDateForViewing(invoiceData.date).getFullYear()}
                      </div>
                      <div style={{ fontSize: 12, marginTop: 3 }}>
                        Sales: {invoiceData.sales}
                      </div>
                      <div style={{ fontSize: 12, marginTop: 3 }}>
                        Secondary: {invoiceData.secondary_sales}
                      </div>
                      <div style={{ fontSize: 12, marginTop: 3 }}>
                        Designer: {invoiceData.designer}
                      </div>
                      <div style={{ fontSize: 12, marginTop: 3 }}>
                        Designer Hp: {invoiceData.phone_number}
                      </div>
                    </div>
                  </div>
                  <div style={{ marginLeft: "5%", marginTop: 15 }}>
                    <div style={{ fontSize: 12 }}>
                      Jobsite Address: {invoiceData.address}
                    </div>
                  </div>
                  <div
                    style={{
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                      width: "90%",
                      marginLeft: "5%",
                      marginTop: 20,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottom: "2px solid black",
                        alignItems: "center",
                        height: "35px",
                        fontWeight: "bolder",
                      }}
                    >
                      <div
                        style={{
                          width: "80px",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: 12, textAlign: "center" }}>
                          SKU
                        </div>
                      </div>
                      <div
                        style={{
                          width: "200px",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: 12, textAlign: "center" }}>
                          Product Description
                        </div>
                      </div>
                      <div
                        style={{
                          width: "80px",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: 12, textAlign: "center" }}>
                          Area
                        </div>
                      </div>
                      <div
                        style={{
                          width: "30px",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: 12, textAlign: "center" }}>
                          Qty
                        </div>
                      </div>
                      <div
                        style={{
                          width: "70px",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: 12, textAlign: "center" }}>
                          Unit Price
                        </div>
                        <div style={{ fontSize: 12, textAlign: "center" }}>
                          (SGD)
                        </div>
                      </div>
                      <div
                        style={{
                          width: "70px",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: 12, textAlign: "center" }}>
                          Total Price
                        </div>
                        <div style={{ fontSize: 12, textAlign: "center" }}>
                          (SGD)
                        </div>
                      </div>
                    </div>

                    {invoiceData.product
                      .slice()
                      .sort((a, b) =>
                        (a.area || "").localeCompare(b.area || "", undefined, {
                          sensitivity: "base",
                        })
                      )
                      .map((product, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              width: "80px",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                padding: 5,
                              }}
                            >
                              {product.SKU}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "200px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              textAlign: "left",
                              padding: "5px 0px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                textAlign: "left",
                                padding: 5,
                              }}
                            >
                              {product.productDescription
                                ? product.productDescription
                                : "None"}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "80px",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                padding: 5,
                              }}
                            >
                              {product.area}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "30px",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                padding: 5,
                              }}
                            >
                              {product.quantity}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "70px",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                padding: 5,
                              }}
                            >
                              $ {parseFloat(product.UnitPrice).toFixed(2)}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "70px",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                padding: 5,
                              }}
                            >
                              ${" "}
                              {parseFloat(
                                product.UnitPrice * product.quantity
                              ).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      height: 20,
                      marginLeft: "5%",
                      width: "90.2%",
                      fontWeight: "bolder",
                    }}
                  >
                    <div
                      style={{
                        width: "320px",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        width: "175px",
                      }}
                    >
                      <div
                        style={{
                          width: "103px",
                          borderRight: "2px solid black",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            textAlign: "center",
                            paddingTop: 3,
                          }}
                        >
                          SUB-TOTAL
                        </div>
                      </div>
                      <div
                        style={{
                          width: "70px",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            textAlign: "center",
                            paddingTop: 3,
                          }}
                        >
                          $ {calculateTotalValueGiven(invoiceData).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>

                  {invoiceData.discount !== 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        marginLeft: "5%",
                        width: "90.2%",
                        fontWeight: "bolder",
                      }}
                    >
                      <div
                        style={{
                          width: "320px",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 20,
                          borderRight: "2px solid black",
                          borderLeft: "2px solid black",
                          width: "175px",
                        }}
                      >
                        <div
                          style={{
                            width: "103px",
                            borderRight: "2px solid black",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              color: "red",
                              paddingTop: 3,
                            }}
                          >
                            DISCOUNT
                          </div>
                        </div>
                        <div
                          style={{
                            width: "70px",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              color: "red",
                              paddingTop: 3,
                            }}
                          >
                            - $ {invoiceData.discount.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {invoiceData.less_cv !== 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        marginLeft: "5%",
                        width: "90.2%",
                        fontWeight: "bolder",
                      }}
                    >
                      <div
                        style={{
                          width: "320px",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 20,
                          borderRight: "2px solid black",
                          borderLeft: "2px solid black",
                          width: "175px",
                        }}
                      >
                        <div
                          style={{
                            width: "103px",
                            borderRight: "2px solid black",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              color: "red",
                              paddingTop: 3,
                            }}
                          >
                            VOUCHER
                          </div>
                        </div>
                        <div
                          style={{
                            width: "70px",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              color: "red",
                              paddingTop: 3,
                            }}
                          >
                            - $ {invoiceData.less_cv.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      height: 20,
                      marginLeft: "5%",
                      width: "90.2%",
                      fontWeight: "bolder",
                    }}
                  >
                    <div
                      style={{
                        width: "320px",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        width: "175px",
                      }}
                    >
                      <div
                        style={{
                          width: "103px",
                          borderRight: "2px solid black",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            textAlign: "center",
                            paddingTop: 3,
                          }}
                        >
                          TOTAL
                        </div>
                      </div>
                      <div
                        style={{
                          width: "70px",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            textAlign: "center",
                            paddingTop: 3,
                          }}
                        >
                          ${" "}
                          {(
                            calculateTotalValueGiven(invoiceData) -
                            invoiceData.discount -
                            invoiceData.less_cv
                          ).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      height: 20,
                      marginLeft: "5%",
                      width: "90.2%",
                      fontWeight: "bolder",
                    }}
                  >
                    <div
                      style={{
                        width: "320px",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        borderRight: "2px solid black",
                        borderBottom: "2px solid black",
                        borderLeft: "2px solid black",
                        width: "175px",
                      }}
                    >
                      <div
                        style={{
                          width: "103px",
                          borderRight: "2px solid black",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            textAlign: "center",
                            paddingTop: 3,
                          }}
                        >
                          9% GST
                        </div>
                      </div>
                      <div
                        style={{
                          width: "70px",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            textAlign: "center",
                            paddingTop: 3,
                          }}
                        >
                          ${" "}
                          {(
                            (calculateTotalValueGiven(invoiceData) -
                              invoiceData.discount -
                              invoiceData.less_cv) *
                            0.09
                          ).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      height: 20,
                      marginLeft: "5%",
                      width: "90.2%",
                      fontWeight: "bolder",
                    }}
                  >
                    <div
                      style={{
                        width: "320px",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        borderRight: "2px solid black",
                        borderBottom: "2px solid black",
                        borderLeft: "2px solid black",
                        width: "175px",
                      }}
                    >
                      <div
                        style={{
                          width: "103px",
                          borderRight: "2px solid black",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            textAlign: "center",
                            paddingTop: 3,
                          }}
                        >
                          AMOUNT PAID
                        </div>
                      </div>
                      <div
                        style={{
                          width: "70px",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            textAlign: "center",
                            paddingTop: 3,
                          }}
                        >
                          ${" "}
                          {(parseFloat((
                            calculateTotalValueGiven(invoiceData) -
                            invoiceData.discount -
                            invoiceData.less_cv
                          ).toFixed(2)) +
                            parseFloat((
                              (calculateTotalValueGiven(invoiceData) -
                                invoiceData.discount -
                                invoiceData.less_cv) *
                              0.09
                            ).toFixed(2))).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginLeft: "5%",
                      width: "90%",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        textAlign: "left",
                        textDecoration: "underline",
                        fontWeight: "bolder",
                      }}
                    >
                      Remarks:
                    </div>
                    <div
                      style={{
                        fontSize: 10,
                        textAlign: "left",
                        marginTop: "3px",
                      }}
                    >
                      {invoiceData.remarks}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginLeft: "5%",
                      width: "90%",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        textAlign: "left",
                        textDecoration: "underline",
                        fontWeight: "bolder",
                      }}
                    >
                      Terms and Conditions:
                    </div>
                    <div
                      style={{
                        fontSize: 10,
                        textAlign: "left",
                        marginTop: "3px",
                      }}
                    >
                      1) All cheques should be crossed and made payable to:{" "}
                      <span
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        GC Lighting & Bath Solutions Pte Ltd
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: 10,
                        textAlign: "left",
                        marginTop: "3px",
                      }}
                    >
                      2) Goods sold cannot be returned & deposit are not
                      refundable
                    </div>
                    <div
                      style={{
                        fontSize: 10,
                        textAlign: "left",
                        marginTop: "3px",
                      }}
                    >
                      3) Kindly state "Invoice no." in payment reference when
                      making payment.
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginLeft: "5%",
                      width: "90%",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ border: "1px solid black", padding: "10px" }}>
                      <div
                        style={{
                          fontSize: 12,
                          textAlign: "left",
                          fontWeight: 600,
                          textDecoration: "underline",
                        }}
                      >
                        The below is our banking details:
                      </div>
                      <div
                        style={{
                          fontSize: 10,
                          textAlign: "left",
                          marginTop: "5px",
                        }}
                      >
                        Payee Name: GC Lighting & Bath Solutions Pte Ltd
                      </div>
                      <div
                        style={{
                          fontSize: 10,
                          textAlign: "left",
                          marginTop: "3px",
                        }}
                      >
                        Bank: Maybank
                      </div>
                      <div
                        style={{
                          fontSize: 10,
                          textAlign: "left",
                          marginTop: "3px",
                        }}
                      >
                        Account No: 04041092841
                      </div>
                      <div
                        style={{
                          fontSize: 10,
                          textAlign: "left",
                          marginTop: "3px",
                        }}
                      >
                        Paynow UEN: 202303689R
                      </div>
                    </div>
                    <div>
                      <img src={logo} style={{ height: "100px" }} />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "90%",
                      marginLeft: "5%",
                      marginTop: "70px",
                      padding: "30px 0px",
                    }}
                  >
                    <div style={{ width: "150px" }}>
                      <div
                        style={{
                          height: 18,
                          marginTop: "3px",
                          textAlign: "center",
                        }}
                      />
                      <div
                        style={{
                          fontSize: 10,
                          marginTop: "3px",
                          textAlign: "center",
                          borderTop: "1px solid black",
                        }}
                      >
                        Received by
                      </div>
                    </div>

                    <div style={{ width: "150px" }}>
                      <div
                        style={{
                          fontSize: 15,
                          marginTop: "3px",
                          textAlign: "center",
                        }}
                      >
                        {invoiceData.sales}
                      </div>
                      <div
                        style={{
                          borderTop: "1px solid black",
                          fontSize: 10,
                          marginTop: "3px",
                          textAlign: "center",
                        }}
                      >
                        Issue by
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Receipt;
